@import url('assets/fonts/stylesheet.css'); 
@import url('assets/fonts/fontawesome/css/all.css');
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Unbounded:wght@200..900&display=swap');

body, html{font-family: "Playfair Display", serif !important; font-size: 14px !important; background: #200217 !important;scroll-behavior: smooth;}
h2{ font-weight: bold !important; margin-bottom: 50px !important; font-size: 48px !important;}
h3{ font-weight: bold  !important; margin-bottom: 30px !important; font-size: 28px !important;}
p{ font-size: 18px; line-height: 35px;  text-align: justify;}
a.link{color: #242ac1; text-decoration: none; transition: all ease 0.75s;}
a.link:hover {color: #000; text-decoration: underline;}
.btn{border-radius: 0px !important; text-transform: uppercase; font-size: 20px !important; position: relative; box-shadow: none !important; transition: all ease 0.75s !important; border-width: 2px !important;}
.accordion-button:focus{box-shadow: none !important;}

.mwc-navbar{padding: 0px !important; transition: all ease 0.75s; background: #171010;  font-family: "Unbounded", sans-serif; height: 95px;}
.mwc-navbar li a{ color: #fff; padding:15px 30px !important; text-transform: uppercase; position: relative; z-index: 1;text-decoration: none;}
.mwc-navbar li a::before{ content: ''; position: absolute; top: 0px; left: 0px; width: 100%; height: 0px; background: #fff; transition: all ease 0.75s; z-index: -1;}
.mwc-navbar li a:hover::before  { height: 100%;}
.mwc-navbar .navbar-brand{padding: 0px;}
.mwc-navbar .navbar-brand img{ height: 45px;}
.mwc-fillbg {height: 65px; /* background: rgba(0, 0, 0, 0.76);border-color: #000;box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.84);backdrop-filter: blur(10px); */}

.mwc-banner{ position: relative; overflow: hidden; background: #200217; height: 770px; }
.mwc-banner .mwc-con{ position: absolute; width: 100%; top: 0px; left: 0px; color: #fff; padding-top: 230px; z-index: 3;}
.mwc-banner .mwc-con h1 {font-family: "Unbounded", sans-serif;text-transform: uppercase;font-weight: 900;margin: 0px;font-size: 25px;text-align: left;position: relative;top: -25px;padding-left: 190px;}
.mwc-banner .mwc-con h3{ font-size: 27px; margin:20px 0px !important; font-size: 25px !important; }
.mwc-banner img{ width: 100%;}
.mwc-banner .mwc-con img {height: 325px; max-width: 100%; width: auto;}
.mwc-banner .mwc-con .yeartxt {font-size: 120px; font-weight: 900;display: flex;align-items: center; flex-direction: column;}
.mwc-banner .mwc-con .powby{ display: flex;  align-items: center;  flex-direction: row; font-size: 20px; text-transform: uppercase; margin-top: -15px;}
.mwc-banner .mwc-con .powby img{ height: 75px; }
.btn-primary {background: #14E3F6 !important;border-color: #14E3F6 !important; color: #000 !important;}
.mwc-banner .btn-custom.btn-primary::before, .mwc-banner .btn-custom.btn-primary::after{ background: #1c0630;}
.mwc-banner .btn-custom.btn-primary:hover{ color: #fff !important;}

.overlaybg::before{ content: ''; position: absolute; left: 0px; top: 0px; width: 100%; height: 100%;  background: rgba(0, 0, 0, 0.5); z-index: 2; }

.btn-custom {min-width: 250px;height: 65px;padding: 0px !important;justify-content: center;align-items: center;display: inline-flex !important; padding: 0px 30px !important;}
.btn-light:hover{background: transparent !important; color: #fff !important;}

.mwc-about, .mwc-agenda, .mwc-speakers, .mwc-sponsors, .mwc-pricing, .mwc-events{ padding: 100px 150px;}
.mwc-about img{border-radius: 16px; transition: all ease 0.75s;}
.mwc-about-conl{ padding-left: 30px;}
.mwc-about-conr{ padding-right: 75px;}
.mwc-about img:hover{box-shadow: 0 0 15px rgb(151, 102, 169);}
#About .mwc-about:nth-child(2n) {background: #000 url(http://localhost:3000/static/media/mec-speakers-bg.35782b8a5b5c0ad7ecc8.png) no-repeat; background-position: center right;}
#About .mwc-about:nth-child(2n) h3, #About .mwc-about:nth-child(2n) p{ color: #fff;}


.btn-custom:hover, .btn-custom:focus{ border-color: #febe98 !important; color: #6a2724 !important; background-color: #febe98 !important;}
/* .mwc-banner .btn-custom.btn-primary:hover, .mwc-banner  .btn-custom.btn-primary:focus{border-color: #febe98 !important; color: #6a2724 !important;} */
.mwc-btn-sec{ margin-top: 75px !important;}
.btnbottomalign{ display: flex; align-items: flex-end; justify-content: flex-end; height: 100%;}
.mwc-agenda{background:#fafaff}
.accordion, .accordion-item{ background: transparent !important; border: none !important;}
.accordion-header {margin-bottom: 0 !important;} 
.accordion-item {background: transparent;}

.accordion-header .accordion-button{background: transparent;  border-radius: 0 !important;color: #fff;font-size: 25px;height: 65px;font-weight: bold; border: none !important;box-shadow: none !important; transition: all ease 0.75s; font-family: "Unbounded", sans-serif; text-transform: uppercase; font-size: 40px;}
.accordion-header .accordion-button[aria-expanded="true"]{ color: #febe98; border-radius: 0px !important; box-shadow: none; background: transparent !important; border: none !important; box-shadow: none !important;}
.accordion-item:nth-child(2) .accordion-header .accordion-button[aria-expanded="true"] {color: #ce8a75;}
.accordion-item:nth-child(3) .accordion-header .accordion-button[aria-expanded="true"] {color: #97504d;}
.accordion-collapse.collapse.show{border: none; border-top-color: transparent; border-radius: 0px 0px 16px 16px; background: transparent;}
.accordion-header .accordion-button[aria-expanded="true"]:hover{background: transparent; color: #febe98;}
.accordion-button::after{ filter: brightness(0) invert(1);}
.accordion-header .accordion-button[aria-expanded="true"]::after{filter: brightness(0) invert(0);}
.accordion-body p, .accordion-body h4{ text-align: left; margin-bottom: 30px;}
.accordion-body p, .accordion-body{font-size: 20px; font-family: "Playfair Display", serif; color: #fff;}
.accordion-body ul li:not(:last-child) {margin-bottom: 15px;}
.accordion-body p strong, .accordion-body h4 .small{ color: #9967AC;}
.accordion-body h4{ font-weight: bold;}
/* .accordion-button::after{ display: none;} */

.mwc-ac-icont{ display: flex; flex-direction: row; align-items: center; margin-bottom: 30px;}
.mwc-ac-icont img{ margin-right: 10px;}
.mwc-ac-icont p{ margin-bottom: 0px; font-size: 16px;}
.mwc-sponsors.mwc-speakers .btn-custom.btn-primary::before, .mwc-sponsors.mwc-speakers  .btn-custom.btn-primary::after{background: #000;}
.mwc-speakers{ background:#200217; background-size: cover; background-position: top center;}
.mwc-speakers h2{ color: #fff;}
.mwc-speakers .col-md-3{ margin-bottom: 30px;}
.mwc-speaker{border-radius: 16px; background: #000 url('/public/assets/img/grad-bg.png') no-repeat; background-position: center;  background-size: cover; min-height: 100%; display: flex; flex-direction: column; align-items: center; padding:30px 15px; transition: all ease 0.75s; transition: all ease 0.75s;}
.mwc-speaker:hover{box-shadow: rgba(0, 0, 0, 0.4) 0px 5px, rgba(0, 0, 0, 0.3) 0px 10px, rgba(0, 0, 0, 0.2) 0px 15px, rgba(0, 0, 0, 0.1) 0px 20px, rgba(0, 0, 0, 0.05) 0px 25px;}
.mwc-speakers .col-md-3:nth-child(2n) .mwc-speaker{background: #000 url('/public/assets/img/grad-bg-rev.png') no-repeat; background-position: center;  background-size: cover; }

.mwc-speaker .mwc-propic{ width: 175px; height: 175px; border-radius: 50%;  background: url('/public/assets/img/mwc-prodefault.png') no-repeat; background-size: cover; background-position: center; margin: 0 auto 15px; overflow:hidden; border: 2px solid transparent; transition: all ease 0.75s;}
.mwc-speaker .mwc-proname{ font-size: 20px; font-weight: bold; margin-bottom: 10px; color: #fff; text-align: center;}
.mwc-speaker .mwc-prodetail{ font-size: 14px; text-align: center; color: #fff;}
.mwc-speaker:hover .mwc-propic{ border-color: #fff; box-shadow: 0px 0px 15px rgb(255, 255, 255);}
/* .mwc-speakers .btn-custom.btn-primary:hover, .mwc-speakers .btn-custom.btn-primary:focus, .btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active, .btn.show{ color: #fff !important; border-color: #fff !important;
    background: transparent !important;} */
.mwc-speakers .btn-custom.btn-primary::before, .mwc-speakers .btn-custom.btn-primary::after{background: #200217;}

.mwc-sponsors{background: #000;}
.mwc-sponsors img{ filter: brightness(0) invert(1);padding: 0 15px;max-width: 250px!important;object-fit: contain;max-height: 135px;}
.mwc-sponsors h2{ color: #fff;}
.mwc-banner + .mwc-sponsors.mwc-speakers {background: #200217;}

.mwc-footer{color: #fff; text-transform: uppercase; padding: 30px 0px; }
.mwc-footer-links{ display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; padding-bottom: 30px; margin-bottom: 30px; border-bottom:1px solid #18171e;}
.mwc-footer-links a{ text-transform: uppercase; color: #fff; padding: 15px 30px;}
.mwc-footer-links a:hover, .mwc-social-links a:hover { color: #9967AC ;}
.mwc-social-links{ display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; align-items: center;}
.mwc-social-links a{ padding:10px; font-size: 20px; color: #fdbd97;}
.mwc-social-links a:hover{ color: #97504d;}

.copyrights{ text-transform: none;}
.copyrights a{ color: #fdbd97; text-decoration: none; transition: all ease 0.75s;}
.copyrights a:hover{ color: #97504d; text-decoration: none;}

.clrlilac{ color: #9967AC;}
.clrblue{ color: #14E3F6;}

.mwc-price-list{ border-radius: 16px; 
    background: linear-gradient(45deg, rgb(87, 62, 113) 25%,rgb(153, 103, 172) 75%,rgb(115, 77, 139) 100%);
    transition: all ease 0.75s;    
     color: #fff; padding: 50px; min-height: 100%;}
.mwc-price-list-secbg{
    background: linear-gradient(45deg, rgb(115, 77, 139) 25%,rgb(153, 103, 172) 75%, rgb(87, 62, 113) 100%);
    }
.mwc-price-list h5, .mwc-price-list h3{ text-align: center; margin-bottom: 30px;}
.mwc-price-list .btn{ margin-bottom: 30px;}
.mwc-price-list ul li{ line-height: 40px; font-size: 18px; position: relative; display: flex;}
.mwc-price-list ul li .checkicon{color: #fff; margin-right: 15px;}

/* .mwc-price-list:hover{} */
.mobilelogo{ display: none;}

@keyframes fadeInUp {
    from {transform: translate3d(0,25px,0)}
    to {transform: translate3d(0,0,0);opacity: 1}
}
    
@-webkit-keyframes fadeInUp {
    from {transform: translate3d(0,25px,0)}
    to {transform: translate3d(0,0,0);opacity: 1}
}
.animated {animation-duration: 1s;animation-fill-mode: both;-webkit-animation-duration: 1s;-webkit-animation-fill-mode: both;animation-delay: 0s;}
.animated2 {animation-duration: 1s;animation-fill-mode: both;-webkit-animation-duration: 1s;-webkit-animation-fill-mode: both;animation-delay: 1.5s;}
.animatedFadeInUp {opacity: 0}
.fadeInUp {opacity: 0;animation-name: fadeInUp;-webkit-animation-name: fadeInUp;}

.mwc-banner .carousel-control-next, .mwc-banner .carousel-control-prev {width: 35px; height: 35px;background: #fff;border-radius: 50%;opacity: 0.8;color: #311847;font-size: 25px;top: 50%;margin-top: -17.5px;}
.mwc-banner .carousel-control-prev {left: 10px;  }
.mwc-banner .carousel-control-next { right: 10px;}
.mwc-banner  .carousel-control-prev:hover, .mwc-banner  .carousel-control-prev:focus, .mwc-banner  .carousel-control-next:hover, .mwc-banner  .carousel-control-next:focus {background: #0000ae;}
  
.builtwallet li {margin:0px 10px 10px;}
.footer-links a {color: #797979;width: 100%;display: inline-block; text-decoration: none; transition: all ease 0.75s;}
.footer-links a:hover {color: #fff;text-decoration: none;}
.onflowicon {width: 24px;position: relative; opacity: 0.6;}
.builtwallet .developlink{ line-height: normal; font-size: 11px; text-transform: none;}
.builtwallet .developlink img{margin: 0px 5px 0px 0px; }
.builtwallet .developlink .text-danger{ color:#797979 !important; }
.builtwallet .developlink span{font-size: 15px;  font-weight: 600;}
.builtwallet .developlink:hover .text-danger{color:#e52828 !important }
.builtwallet li:hover .onflowicon{opacity: 1;}
.builtwallet .developlink .devlotxt {display: flex; flex-direction: column; font-weight: normal; font-size: 12px;}
.builtwallet .developlink .devlotxt span{ font-weight: 600;}
.developlink a{  display: flex; flex-direction: row; justify-content:  flex-end; align-items: center;}
.socialdevlop{ display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; align-items: center;}
.footer-links.builtwallet{ margin-bottom: 0px; list-style: none; padding: 0px;}
.copyrights{ text-align: center; font-family: "Figtree", sans-serif;}

.sticth{text-decoration: line-through; color:#7377ff; font-size: 33px;}

.mwc-item .ab-img{ margin-bottom:30px}

.mwc-about-bgnone{background-image: none !important;}
.mwc-about-bgnone img{ box-shadow: none !important;}
.crossmark{ font-size: 50px;}
.dftxt{ letter-spacing: 10px; text-align: center;}
.dshtxt{ font-size: 70px !important; text-transform: uppercase;}

.mwc-about-venue { padding-top: 30px;}
.mwc-about-venue h3 { display: flex; flex-direction: row; align-items: center; margin-bottom: 15px !important; color: #9967ac; font-size: 22px !important;}
.mwc-about-venue h3 img{ max-width: 48px; box-shadow: none !important; margin-right: 10px;}
.mwc-about-venue p {padding-left: 65px;}
.mwc-about-venue a{ color: #9967AC; text-decoration: none;display: inline-flex;}
.mwc-about-venue a:hover{ color:#14E3F6;}
.mwc-about-venue a img{ width: 32px; margin:0px 10px 0px 0px; box-shadow: none !important; border-radius: 0px; }

.imgcontmid{ margin-bottom: 30px; text-align: center;}
.imgcontmid h3{ margin-top: -60px; color: #fff; font-size: 25px !important; margin-bottom: 30px !important;}
.imgcontmid p{ font-size: 14px; text-align: center; line-height: 25px; }

.lastmap{ display: flex; flex-direction: row; align-items: center;}

.w115px a{ width: 115px; background: none !important; cursor: none !important; padding: 0px !important;}
.w115px a::before{ display: none;}

.mwc-speaker .mwc-propic img {width: 100%;height: 100%;object-fit: cover;object-position: top;}


/* .mwc-events{} */
.mwc-events-list { padding: 30px; background: #fafaff;border-radius: 30px; box-shadow: 0px 0px 5px rgba(0,0,0,0.2);}
.mwc-event{display: block; position: relative; height: 550px; width: 100%; border-radius: 15px;   overflow: hidden; cursor: pointer; margin-bottom: 30px;}
.mwc-event::before{ content: ''; position: absolute; background: #242ac1; border-radius: 50%; width: 100%; height: 100%; bottom: -100%; left: -100%; transition: all ease 1s; z-index: 1; opacity: 0;}
.mwc-event img{position: absolute;  left: 50%;  top: 50%;  -webkit-transform: translate(-50%,-50%);  transform: translate(-50%,-50%); object-fit: cover;  margin: 0 auto; height: 550px; width: 100%;}
.mwc-event-details{ position: absolute; border-radius: 15px; padding: 15px; background: #fff; bottom: 15px; left: 50%; transform: translateX(-50%); width: 90%; transition: all ease 0.75s; min-height: 0px; z-index: 1; color: #120b2b;}
.mwc-event-details h4 {font-size: 18px; margin-bottom: 0px; text-align: left;}
.mwc-event-details p {font-size: 16px; line-height: 20px; color: #5b6e9b; text-align: left; margin-bottom: 0px;}
.mwc-event-details .btn{ font-size: 14px !important; text-transform: none; position: absolute; bottom: -100px; opacity: 0; transition: all ease 0.75s; background: transparent; border-color: #fff; }

.mwc-event:hover::before{width: 150%; height: 150%; opacity: 0.9; bottom: -25%; left: -25%;}
.mwc-event:hover .mwc-event-details{ min-height: 90%; background: transparent; }
.mwc-event:hover .mwc-event-details h4{ color: #fff;}
.mwc-event:hover .mwc-event-details P{ color: #becbea;}
.mwc-event:hover .mwc-event-details .btn{bottom:30px; transition: all ease 0.75s; opacity: 1; margin-top: 30px;}

.innerheader h1{ font-size: 50px; margin-bottom: 0px;}
.mwc-events .nav-pills{ justify-content: center; align-items: center;}
.mwc-events .nav-pills .nav-link, .mwc-events .nav-pills .show > .nav-link{font-size: 25px; padding: 10px 30px; color: #000;}
.mwc-events .nav-pills .nav-link small{font-size: 15px;}
.nav-link { transition: all ease 0.75s;}
.pills-gv .nav-pills{ background: #fff; } 
.pills-gv .nav-link img {width: 28px; margin-right: 10px; filter: brightness(0); transition: all ease 0.75s;}
.mwc-events .pills-gv .nav-link, .mwc-events .pills-gv .show > .nav-link{padding:10px 15px; justify-content: center;  align-items: center;  display: inline-flex; border: 2px solid transparent; font-size: 14px; transition: all ease 0.75s;}
.mwc-events .pills-gv .nav-link:hover,.mwc-events .pills-gv .nav-link.active, .mwc-events .pills-gv .show > .nav-link{ color: #fff; background: #242ac1; border-color: #242ac1; box-shadow: 0px 3px 3px rgba(0,0,0,0.2);}
.evegallery-noart{width: 200px;height: 200px;}
.mwc-events .pills-gv .nav-link:hover img, .mwc-events .pills-gv .nav-link.active img{ filter: brightness(0) invert(1)}

.mwc-events #pills-tab{ justify-content: flex-start;}
.mwc-events #pills-tab .nav-item {text-align: center;padding: 0px 15px;}
.mwc-events .nav-pills .nav-item span {margin-top: 5px; display: block; font-size: 13px;}
.mwc-events .nav-pills .nav-item .nav-link{width: 100%;}

.customcard{ padding: 30px; border: none; box-shadow: 0px 1px 2px rgba(0,0,0,0.1); margin-bottom: 30px;}

div#Speakers ul {width: 100%;display: flex;align-items: center;justify-content: space-between;}
div#Speakers ul > div {margin: 0!important;}
.mwc-speakers h2 {font-size: 30px!important;}
div#Speakers ul > div .btn-custom {height: 50px;}
div#Speakers > h2 {font-size: 38px!important;text-align: center;line-height: 45px;margin-bottom: 50px !important;}
#mc_embed_signup { background:transparent; clear:left;  width:100%}
.asc-popup #mc_embed_signup {position:relative;text-align: center;}
.clsbtn {top:0;right:0;position:absolute;top:-36px;right:-16px;background:transparent;border:none;-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px);opacity:.5;    font-weight:500}
.clsbtn:hover {background:rgba(0,0,0,.3)}
.asc-popup .modal-content {background:linear-gradient(45deg, #00b7ff, #8d42b0, #fff);-webkit-backdrop-filter:blur(10px);backdrop-filter:blur(10px);box-shadow:15px 15px 10px rgba(0,0,0,.5); border: none;}
.asc-popup #mc_embed_signup h2 {color:#fff;text-align:center;text-transform:uppercase;margin-bottom:30px;font-size:30px;/* letter-spacing:10px; */margin-top:0px}
.asc-popup #mc_embed_signup .helper_text {background:transparent}
.asc-popup #mc_embed_signup .mc-field-group input {outline:none!important;border:none;border-bottom:1px solid #fff;border-radius:0;background:transparent;color:#fcb52a;letter-spacing:1px;    position:relative}
.asc-popup #mc_embed_signup .mc-field-group input:before {content:"";position:absolute;width:100%;height:1px;bottom:0;left:0;background:red}
.asc-popup .indicates-required {display:none}
.asc-pimg {max-width:50%}
.asc-popup h4 {font-size:18px;text-align:center;text-transform:uppercase;letter-spacing:5px}
.asc-popup #mc_embed_signup .mc-field-group label,.asc-popup h4 {color:#fff;margin-bottom:0;font-weight:500}
.asc-popup #mc_embed_signup .mc-field-group label {letter-spacing:1px}
#mc_embed_signup .button {text-transform:uppercase;letter-spacing:1px;font-size:13px;margin:0;float:right;border-radius:30px}
#mc_embed_signup .mc-field-group {width:100%}

@keyframes modalFade {
0% {transform:translateY(-50%)}
to {transform:translateY(0)}
}

.modal {animation-name:modalFade;animation-duration:.75s}
#mc_embed_signup .button, ._green .add_box .-txt .-btn, ._green .ban_btn {background: #c0a9e2;
    border: 1px solid #fff;
    box-shadow: 0 0 15px 2px #c0a9e2;
    transition: all .75s ease;
    color: #fff;
    min-height: 48px;}
#mc_embed_signup .button:hover, ._green .add_box .-txt .-btn:hover, ._green .ban_btn:hover {outline: 2px solid #c0a9e2;background: transparent;-webkit-backdrop-filter: blur(10px);backdrop-filter: blur(10px);}
#mc_embed_signup .clsbtn.btn.btn-primary {border-radius: 10px !important; background: #00000036 !important;  border: none;}

.mwc-speaker .mwc-ac{ font-size: 18px;}

.mwca-cont{ padding: 30px; margin-bottom: 30px; border-radius: 30px; background: #fff; box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);}
.mwca-cont p{ margin-bottom: 0px;}

div#Sponsors .row > div {
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .map_icon img {
      width: 25px;
      margin-left: 10px;
  }
  .map_head {
      display: flex;
      align-items: center;
      justify-content: center;
  }
  ul.sponsors_imgs {
      margin: 20px 0 0;
     display: flex;
     align-items: center;
     justify-content: flex-start;
     padding: 0;
  }
  ul.sponsors_imgs li {
      list-style: none;
  }
  ul.sponsors_imgs li:not(:last-child) {
      margin-right: 25px;
  }
  ul.sponsors_imgs li img {
      max-width: 200px;
      min-width: 200px;
      max-height: 135px;
    object-fit: contain;
  }
  .mwc-speaker > a {
      text-decoration: none;
  }

  .mwca-cont .table-responsive table.table {
      width: 100%;
      max-width: 100%;
      margin: 30px 0 0;
      border: 1px solid #e1e1e1;
      text-align: left;
  }
  .mwca-cont .table-responsive table.table thead tr th {
      padding: 15px 15px;
      font-weight: bold;
      border: 1px solid #e1e1e1;
      font-size: 16px;
  }
  .mwca-cont .table-responsive table.table tbody tr td {
      padding: 10px 15px;
      border: 1px solid #e1e1e1;
      font-size: 14px;
  }
  .mwca-cont .table-responsive table.table tbody tr td b {
      font-size: 16px;
  }
  a.map_icon {
      text-decoration: none;
      padding-left: 10px;
  }
  .mwca-cont p img {
      max-width: 200px;
      object-fit: contain;
  }
  .mwca-cont p img.invert_img {
      filter: invert(1);
  }
  .mwc-about-venue h3 {
      display: block;
      line-height: 40px;
  }
  .mwc-about-venue a {
      display: initial;
      padding-left: 10px;
  }
  .mwc-about-venue a img {
      margin: -20px 0 0 10px;
  }
  .spk_blk .img {
    text-align: center;
}
div#About .row .col-md-7 {
    text-align: center;
}

.cursor-pointer{ cursor: pointer;}

.py50{ padding: 50px 0px;}
.py60{ padding: 60px 0px;}
.pb60{ padding-bottom: 60px;}
.pt60{ padding-top: 60px;}
.pfdf40{ font-family: "Playfair Display", serif; font-size: 48px !important; color:#ffccae; font-weight: 400 !important;}
.pfdf45{ font-size: 54px !important;}
.pfdf36{font-size: 36px !important; line-height: 1.29807567em; margin-bottom: 0px;}
.pfdf26{ font-size: 26px !important; line-height: 1.2896276em;}
.clrffccae{color:#ffccae;}
.clrfffbf9{color: #fffbf9 !important;}
.clr38b6ff{color:#38b6ff;}
.clrff4b4b{color:#ff4b4b;}
.imgh400{ position: relative; height: 400px; overflow: hidden;}
.imgh350{ position: relative; height: 350px; overflow: hidden; width: 380px; margin-left: auto;}
.imgh400 img, .imgh350 img{ position: absolute; left: 50%; top: 50%; transform: translate(-50%,-50%); width: 100%;}
.revengwrap{ padding-left: 70px;}
.revengwrap .imgh350{ margin-bottom: 115px;}
#c-banner{ padding-top: 95px;}
.pe-50{ padding-right: 50px;}
.pe-275{ padding-right: 275px;}
.mb50{ margin-bottom: 50px !important;}
.mt95{ margin-top: 95px !important;}
.mapiconsvg {position: absolute;width: 98px;right: 45px;bottom: 170px;display: flex;flex-direction: column;align-items: center;}
.mapiconsvg img:nth-child(2) {height: 15px;width: auto; }
.mapiconsvg img:nth-child(1) {height: 60px;width: auto; position: relative; right: 1px; animation: bounce2 2s ease infinite;}
@keyframes bounce2 {
      0%{transform: translateY(5px);}
      50% {transform: translateY(-5px);}
      100% {transform: translateY(5px);}
      
 }
.mt200{ margin-top: 200px;}
.revengwrap .carousel-item {background: #200217;}
.mw-1040{ max-width: 1040px; margin: 0px auto;}
.mw-1140{max-width: 1140px; margin: 0px auto;}
.py250{ padding: 250px 0px;}
.imgcenter{ overflow: hidden;}
.imgcenter .imgcenterimg {position: absolute;left: 0px;top: 50%;transform: translateY(-50%);width: 100%;}
.imgcenter .carousel-item {height: 770px;}
.threeimgbox img{ max-height: 295px;}
.brdrtop{border-top-left-radius: 50%; border-top-right-radius: 50%;}
.brownbgsec{ background: #97504d;}
.psitem{text-decoration: none;}
.psitem:hover h4{ color: #200217 !important;}
.psitemimg{ width:225px; height: 300px; position: relative; overflow: hidden; margin: 0px auto; margin-bottom: 15px;}
.owl-theme3 .item{ width: 225px;}
.psitemimg img{ position:absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); height: 325px; width: auto !important;}
.btnwithtxt{ display: flex; align-items: center; justify-content: flex-end;}
.whiteimg img{ filter:brightness(0) invert(1) ;}
.otpwrap{ display: flex; flex-direction: row; max-width: 980px; margin: 0px auto;} 
.tpimgwrap{ width: 225px; overflow: hidden; height: 555px; min-width: 225px;}
.tpimgwrap img{ max-height: 100%;}
.new-accor{ margin-top: 100px;}                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          
.new-accor .accordion-header .accordion-button {height: 105px;}
/* .new-accor .accordion-header .accordion-button[aria-expanded="true"]{height: 0; opacity: 0; padding: 0px;} */
.new-accor .accordion-item:nth-child(1) .accordion-header .accordion-button:hover{ color: #febe98;}
.new-accor .accordion-item:nth-child(2) .accordion-header .accordion-button:hover{ color: #ce8a75;}
.new-accor .accordion-item:nth-child(3) .accordion-header .accordion-button:hover{ color: #97504d;}
.new-accor .accordion-item{ margin-bottom: 22px;}
.mw845{ max-width: 845px; font-family: "Playfair Display", serif; font-weight: 600; color:#ffccae; font-size: 65px; text-transform: uppercase; line-height: normal;}

.commmunitywrap{position: relative; padding: 250px 0px; overflow: hidden;}
.commmunityimg{ position: absolute; right: 0; top: 0; height: 100%; text-align: right; width: 1080px; overflow: hidden;}
.commmunityimg::after{ content: ''; position: absolute; right: 0px; top: 0px; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); z-index: 2;}
.commmunitywrap .container{ z-index: 1; position: relative;}
.commmunityarrow{ position: absolute; top: 300px; left: 600px; height: 80px; width: 225px;}
.commmunityarrow img{ max-width: 100%;}
.commmunityimg img {position: absolute; left: 0; top: 50%; transform: translate(0%, -50%); z-index: 1;}

.mw-auto{ max-width: inherit !important;}

.imgcenh100{ padding: 0px !important; height: 100%; align-items: center; display: flex;}
.mh-auto{ min-height: auto !important;}

.btn-custom {
    font-size: 18px !important;
    color: #febe98 !important;
    text-align: center !important;
    transition: 0.5s all ease-in-out !important;
    font-weight: bold !important;
    font-family: "Unbounded", sans-serif !important;
    border: 10px solid #febe98 !important;
    background: transparent !important;
  }
.btn-custom:hover {background: #febe98 !important;}

.brownbgsec .owl-prev, .brownbgsec .owl-next {
    font-size: 75px !important;
    color: #fff !important;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 300px;
  }
  /* .brownbgsec .owl-prev{ display: none;} */
  .brownbgsec .owl-next{ left: auto !important; right: 0 !important;}

.nmapbg{ background: url('/public/assets/img/new-map-bg.png') no-repeat left top; min-height: 610px;}
.tiwrap{ margin-top: 75px; display: flex; align-items: center; justify-content: center;}
.tiwrap p{ color: #000 !important;}
.tiwrap img{ width:175px; margin-left: 30px;}
.mandatory{ color: red; font-size: 12px; font-weight: normal; position: relative;}
.transform-none{ text-transform: none !important;}
.agenda-iframe{width: 100%; height: calc(100vh - 100px); overflow: hidden; overflow-y: auto;}
.agenda-modal.modal .modal-dialog{height: calc(100vh - 75px); max-width: 75% !important}

.react-pdf__Page__canvas { margin: 0 auto; width: 80% !important; height: 100% !important;}

.banimgwrap{ display: flex; max-width: 100%;}
.banimgwrap img{max-width: 100%; height: 185px;}
.banimgwrap img:not(:first-child){ margin-left: 30px;}

.midbanimgwrap img { max-width: 320px !important; height: auto !important;}
.mwc-event-schedule {
    padding-top: 110px;
}

.midbanimgwrap {width: 100%; text-align: right;}


@media(max-width:1600px){
    .commmunityimg{ width: 1145px;}   
}
@media(max-width:1536px){
    .commmunityimg{ width: 1115px;}   
}
@media(max-width:1440px){
    .commmunityimg{ width: 1065px;}   
}
@media(max-width:1366px){
    .pfdf40{font-size: 33px !important;}
    .revengwrap .pfdf40 {font-size: 34px !important;}
    .imgh350{width: 325px; height: 325px;}
    .pfdf40.pfdf26 {font-size: 20px !important;}
    .mapiconsvg{right: 25px; bottom: 145px;}
    .imgcenter .pfdf40, .py60 .pfdf40.mb50 {font-size: 46px !important;}
    .custom-navigation button.prev {left: -30px;}
    .custom-navigation button.next {right: -30px;}
}


@media(max-width:1280px){    
    .mwc-about, .mwc-agenda, .mwc-speakers, .mwc-sponsors, .mwc-pricing, .mwc-events {padding: 75px 100px;}
    h3{font-size: 28px !important;}
    h2{font-size: 35px !important;}
    p {font-size: 16px; line-height: 30px;}    
    .btn{font-size: 16px !important;}
    .btn-custom {min-width: 200px; height: 60px;}
    .mwc-about-conl {padding-left: 50px;}    
    .mwc-price-list ul li{ font-size: 16px;}
    .mwc-about .row { margin-bottom: 0px; }    
    .mwc-event{ height: 350px;}
    .mwc-banner .mwc-con .yeartxt {font-size: 70px;}
    .imgcenter .pfdf40, .py60 .pfdf40.mb50 {font-size: 33px !important; }
    .new-accor .accordion-header .accordion-button{ font-size: 35px;}
    .mw845{font-size: 55px;}
    .commmunityarrow{top: 260px; left: 500px;}
   
}

@media(max-width:1199px){
    .mwc-abouttop .col-md-6 { width: 100%; }
    .mwc-about-conl {padding-left: 0;}
    .mwc-about .row{text-align: center;}
    .mwc-about img{ margin-bottom: 60px;}
    .imgcontmid img{ margin-bottom: 15px;}
    .mwc-about-venue h3 img{ margin-bottom: 0px;}
    ul.sponsors_imgs li img {margin: 0;}
    .mwca-cont p img {margin-bottom: 0;}    
    .revengwrap .pfdf40 {font-size: 30px !important;}
    .imgcenter .pfdf40, .py60 .pfdf40.mb50 { font-size: 32px !important;}
    .revengwrap .pfdf40 {font-size: 30px !important;}
    .revengwrap .carousel-inner .pfdf40{font-size: 20px !important;}
    .commmunitywrap{padding: 200px 0px;}
    .commmunityimg {width: 960px;}
    .commmunityimg img{transform: translate(-20%, -50%); left: 30%;}
    .container{width: 90% !important; max-width: 90% !important;}
    .mwc-footer {padding: 50px 0px !important;}
    .mwc-banner .mwc-con img {height: 325px;}
    .mwc-banner .mwc-con .row .col-8 {width: 55%;}
    .mwc-banner .mwc-con .row .col-4 {width: 45%;}
    .mwc-navbar li a, .mwc-navbar li button {font-size: 15px;}
}

@media(max-width:1024px){
    .mwc-navbar li a, .mwc-navbar li button { padding: 15px !important;}
    .mwc-navbar .navbar-brand img { height: 35px; }        
    .mwc-about, .mwc-agenda, .mwc-speakers, .mwc-sponsors, .mwc-pricing, .mwc-events {padding: 75px 50px;} 
    .mwc-footer{padding: 50px 30px;} 
    .mwc-banner .mwc-con h1{font-size: 20px; padding-left: 135px;}
    .mwc-banner .mwc-con .yeartxt img {height: 300px;}
    .mwc-banner{height: auto; min-height: 685px;}
    .revengwrap .pfdf40 {font-size: 26px !important;}
    .imgh350 {width: 300px;height: 300px;}
    .imgcenter .pfdf40, .py60 .pfdf40.mb50 {font-size: 26px !important;}
    .mapiconsvg {right: 10px;bottom: 115px;}
    .mt200 {margin-top: 155px;}
    .imgcenter .carousel-item {height: 550px;}
    .threeimgbox img {max-height: 215px;}
    .pe-275 {padding-right: 0px;}
    .commmunityimg {width: 800px;}
    .custom-navigation button.prev, .custom-navigation button.next {display: none;}

}
@media(max-width:991px){
    .container {width: 95% !important; max-width: 95% !important;}
    .mwc-navbar{ height: 64px;}
    .mwc-banner{min-height: 660px;}
    .mwc-banner .mwc-con{padding-top: 175px;}
    .mwc-about-conl, .mwc-about-conr {padding:0px 0px 15px 0px;}   
    .navbar-toggler{background: transparent !important;}
    .mwc-navbar li a, .mwc-navbar li button{ width: 100%;}
    .navbar-toggler-icon {filter: brightness(0) invert(1);}
    .navbar-toggler:focus{box-shadow: none !important;}
    .navbar-collapse{ background-color: #171010; position: absolute; top: 100%; width: 100%;     left: 0px; box-shadow: 0px 15px 30px rgb(77, 55, 43);}
    .desktoplogo{ display: none;}
    .mobilelogo{ display: block; padding: 5px 10px !important; }    
    .mwc-navbar .navbar-brand img {height: 35px; max-width: inherit !important;}
    .mwc-fillbg .navbar-collapse{ background: transparent;}
    .mwc-speakers-list .col-md-3 {width: 33.333%;}
    .mwc-about, .mwc-agenda, .mwc-speakers, .mwc-sponsors, .mwc-pricing, .mwc-footer, .mwc-events{padding: 75px 30px;}
    .mwc-agenda .col-md-4 {width: 50%;}
    .mwc-price-list{padding: 50px 25px;}
    .mwc-price-list ul li { font-size: 14px; }
    .mwc-btn-sec {margin-top: 25px !important;}
    .w115px{ display: none;}
    .mwc-events-list .col-md-3 {width: 50%;}    
    #c-banner .col-md-7, #c-banner .col-md-5 {width: 100%;}
    .imgh350{margin: 30px auto; width: 100%; min-height: 500px;}
    .mapwrap .col-md-6 {width: 100%; }
    .mapwrap .imgh4001{ max-width: 400px; margin: 0 auto; position: relative;}
    .mapiconsvg {right: 0px;bottom: 100px;}
    .mt200 { margin-top: 50px; }
    .btn-custom{font-size: 14px !important; border: 8px solid #febe98 !important;}
    .pfdf40.pfdf36 {font-size: 24px !important; }
    .brownbgsec .mt95{ margin-top: 30px !important;}

    .banimgwrap img {height: 55px;}
}

@media(max-width:830px){
    .mwc-pricing .col-md-6 {width: 100%;  margin-bottom: 50px;}
    .accordion-header .accordion-button{background-position: right center; background-size: 85%;}
    .accordion-item{background-position: center;}
    .lastmap{flex-direction: column; align-items: flex-start;}
    .lastmap a{margin-top: 10px;}
    
}
@media(max-width:767px){
    .container {
        width: 100% !important;
        max-width: 100% !important;
      }
    .mwc-banner { height: 425px;overflow: hidden; min-height: auto;}
    .mwc-banner .mwc-con .yeartxt img {height: 160px;}
    .mwc-agenda .col-md-4 {width: 100%;}
    .mwc-speakers-list .row {flex-wrap: nowrap; overflow-x: auto;}
    .mwc-speakers-list .col-md-3 { width: 66.66%;   }
    .mwc-footer-links a{padding: 15px;}
    .mwc-footer{ text-align: center; padding-top:50px; padding-bottom: 50px; font-size: 12px;}
    .mwc-social-links{justify-content: center; margin-top: 15px;}        
    .mwc-speaker{ min-height: auto; height: 100%; margin-bottom: 0px;}  
    .mwc-about-venue { padding-top: 30px;}
    .dshtxt { font-size: 35px !important;}
    .dftxt { letter-spacing: 5px;}
    ul.sponsors_imgs {flex-wrap: wrap;}
    ul.sponsors_imgs li:not(:last-child) {margin-bottom: 25px;}
    .mwc-sponsors img {max-width: 100%!important;}
    .mwc-banner + .mwc-sponsors.mwc-speakers > div { margin: 0 !important;}
    .map_head {display: block;}
    a.map_icon img {margin-bottom: 0;}
    .mwc-banner .mwc-con h1 {font-size: 15px;  padding-left: 72px; top: -18px;}
    .imgcenter .carousel-item {height: 350px;}
    .threeimgbox img {max-height: 165px;}
    .tpimgwrap {width: 160px;height: 400px;min-width: 160px;}
    .new-accor {
        margin-top: 70px;
      }
      .new-accor .accordion-header .accordion-button {
        height: 75px;
        align-items: center; justify-content: center;
      }
      .new-accor .accordion-header .accordion-button {
        font-size: 26px;
      }
      .mw845 {
        font-size: 34px;
      }
      .commmunityimg {
        width: 80%;
      }
      .commmunitywrap {padding: 100px 0px;}
      .commmunityimg img{height: 765px;}
      .commmunityarrow {
        top: 160px;
        left: 315px;
        width: 100px;
      }
      .pe-275 {
        padding-right: 28%;
        margin-top: 30px !important;
      }
      .otpwrap{ flex-direction: column; align-items: center;}
      .socialdevlop{ flex-direction: column;}
      .mwc-social-links, .copyrights{ margin: 0px 0px 30px;}
      .revengwrap {padding-left: 0;}
      .banimgwrap {
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }
    .banimgwrap img {
        height: 75px;
    }
    .midbanimgwrap img { max-width: 200px !important; margin-top: 30px; }
}
@media(max-width:575px){
    
    .accordion-header .accordion-button {background-size: 100%;}
    .mwc-price-list p{ text-align: left;}
    .copyrights{ line-height: 20px;}
    .socialdevlop{ margin-top: 0px; flex-direction: column;}
    .mwc-events-list .col-md-3 {width: 100%;}
    div#Speakers > h2 {font-size: 30px!important;line-height: 40px;}
    .mwc-sponsors img {max-width: 100% !important;}
    .mwca-cont .table-responsive table.table {width:800px;max-width: initial;}
    .threeimgbox .col-sm-4{ width: 100%; text-align: center !important; margin-bottom: 30px;}
    .pfdf40.pfdf36{margin-top: 30px !important;}
    .pe-275{ padding-right: 0px;}
    .mwc-banner .mwc-con .col-8, .mwc-banner .mwc-con .col-4 {
        width: auto;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0px auto;  
      }
      .mwc-banner .mwc-con {padding-top: 120px; }
      .mwc-banner .mwc-con .col{ margin-top: 30px;}
      .mwc-banner .carousel-inner .carousel-item > img {min-height: 430px;}
      .revengwrap .imgh350 {
        margin-bottom: 50px;
      }
      .mapwrap h3 {
        text-align: left !important;
        padding-bottom: 0px !important;
      }
      .btnwithtxt  .pfdf40.pfdf36 {
        margin-top: 0px !important;
      }
      .midbanimgwrap { width: auto; text-align: center;}
}

@media(max-width:475px){
    .pfdf40 {
        font-size: 30px !important;
      }
      .imgh400{height: 250px;}
      .imgcenter .pfdf40, .py60 .pfdf40.mb50 {font-size: 26px !important;}
      .pfdf40.pfdf36 {font-size: 22px !important;}
    h3 {font-size: 20px !important; margin-bottom: 15px !important;}
    h2 {font-size: 30px !important; margin-bottom: 30px !important;}
    .mwc-speakers-list .col-md-3 {width: 75%;}
    .mwc-speaker{min-height: auto;}
    .mwc-sponsors p {text-align: left;}
    .mwc-price-list ul li .checkicon{margin-right: 10px;}
    .mwc-footer-links a {padding: 15px 10px;}
    .mwc-about, .mwc-agenda, .mwc-speakers, .mwc-sponsors, .mwc-pricing, .mwc-footer, .mwc-events {padding: 75px 15px;}
    .dftxt{ font-size: 12px; letter-spacing: normal;}
    .mwc-footer {
        padding: 30px 0px !important;
      }
      .mw845 {
        font-size: 24px;
      }
      .commmunityarrow {
        top: 110px;
        left: 223px;
        width: 75px;
      }
      .pe-275 {
        padding-right: 20%;
      }
      .commmunityimg img {
        height: 600px;
      }
      #c-banner {
        padding-top: 50px;
      }
}

@media (max-width: 414px){
    .pfdf40 {
        font-size: 32px !important;
      }
.btn {font-size: 13px !important;}
.mwc-ac-icont{margin-bottom: 15px;}
.accordion-header .accordion-button{font-size: 20px;}
.mwc-ac-icont img{height: 24px;}
.mwc-ac-icont p {font-size: 13px;}
.mwc-speakers-list .col-md-3 {width: 90%;}
.asc-popup #mc_embed_signup h2{ font-size: 24px; letter-spacing: normal;}
.imgh400 {height: 200px;}
.imgh350{min-height: auto; height: 250px;}
.new-accor .accordion-header .accordion-button {
    font-size: 20px;
    height: 50px;
  }
  .pe-275 {
    padding-right: 5%;
  }
  .tpimgwrap {
    width: 80px;
    height: 200px;
    min-width: auto;
  }
  .new-accor {
    margin-top: 15px;
  }
  .btnwithtxt .me-5 {
    margin-right: 15px !important;
  }
  .btn-custom{ min-width: auto}
  .mw845 {
    font-size: 23px;
  }

  .midbanimgwrap img {
    max-width: 145px !important;}
  
}







/*VERSION2 THEME 2024 */
.mwc-navbar li button::before, .mwc-navbar li a::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 0px;
    background: #692623;
    transition: all ease 0.75s;
    z-index: -1;
}
.mwc-navbar li button:hover::before {
    height: 100%;
}

.mwc-navbar li a, .mwc-navbar li button {
    font-size: 16px !important;
    line-height: 28px;
    text-transform: uppercase;
    padding: 20px!important;
    font-weight: bold;
    background: transparent;
    border: none;
    color: #febe98 !important;
}
.mwc-banner .mwc-con .powby > span {
    font-size: 32px;
    line-height: 45px;
    margin: 10px 0;
    
    font-weight: 500;
}
.mwc-banner .mwc-con h3 span {
    display: block;
}
.mwc-banner .mwc-con h3 {
    font-size: 25px !important;
    line-height: 30px;
    
    font-weight: 400!important;
}
.mwc-banner .mwc-con h2 {
    font-size: 27px!important;
    line-height: 30px;
    
    font-weight: 400!important;
}
div#s_logos {
    background: #9966ac;
    position: relative;
}

.c_banner {
    width: 100%;
    position: relative;
}
.c_banner img {
    width: 100%;
    object-fit: cover;
    object-position: center;
}
.c_banner .c_banner_txt {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
}
.mwc-c_banner {
    padding: 30px 150px;
}
.c_banner .c_banner_txt p {
    margin: 0;
    display: inline-block;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    
    letter-spacing: 5px;
    padding: 45px 20px;
    text-transform: uppercase;
}
.c_banner .c_banner_txt p a {
    text-decoration: underline;
    color: #fff;
    display: block;
}
.c_banner .c_banner_txt p a:hover{text-decoration: none;}
.c_banner .c_banner_txt p span {
    display: block;
}
.mwc-about-conr h2 {
    font-size: 45px !important;
    line-height: 45px;
    font-family: 'PP Telegraf';
    font-weight: bold!important;
    color: #8d42b0;
    margin-bottom: 25px !important;
}
.mwc-about-conr p {
    font-size: 18px;
    line-height: 26px;
    font-family: 'open sans';
    font-weight: 500;
    color: #000000;
}

.mwc-social-links h4 {
    font-size: 18px !important;
    line-height: 30px;
    font-family: 'PP Telegraf';
    font-weight: bold;
    color: #fff;
    margin: 0 20px 0 0;
}

div#new_Sponsors .row {
    align-items: center;
}

.pillar_modal .modal-content {
    background: linear-gradient(45deg, #00b7ff, #8d42b0, #ffffff);
    border: 3px solid #fff;
}

.d-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 50px;
    height: 50px;
}
.d-icon img {
    width: 100% !important;
    object-fit: contain;
    object-position: center;
    height: 50px!important;
    padding: 0!important;
}
.mwc-banner + .mwc-sponsors.mwc-speakers, div#c-banner, div#About, div#Speakers, div#awards, div#archived, div#new_Sponsors, div#pillars {
    position: relative;
}
/* .navbar-expand-lg .navbar-nav {
width: 30%;
}
.mwc-navbar .navbar-brand {
width: 40%;
text-align: center;
} */
.navbar-expand-lg .navbar-nav:last-child {
    justify-content: flex-end;
}

body {
    overflow-x: hidden;
}

/*ABOUT*/
div#About {
    width: 100%;
    background: url(../public/assets/img/mwc-banner-3.png) no-repeat center;
    background-size: cover;
}
div#About .img {
    margin-top: 30px;
}
div#About .img > img {
    width: 100%;
    object-fit: contain;
    object-position: center;
}
div#About .txt .mwc-about-conr {
    padding: 0;
}
div#About .txt .mwc-about-conr h2 {
    font-size: 45px !important;
    line-height: 50px;
    color: #8d42b0;
    
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: rgba(255, 255, 255, 0.5) 2.65164px 2.65164px 0px, rgba(255, 255, 255, 0.3) 5.30328px 5.30328px 0px;
}
div#About .txt .mwc-about-conr > p {
    font-size: 18px !important;
    line-height: 28px;
    color: #fff;
    
    font-weight: 400;
}
div#About .txt .mwc-about-conr > p span {
    font-weight: bold;
}
div#About .txt .mwc-about-conr .trt {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 30px;
}
div#About .txt .mwc-about-conr .trt h3 {
    font-size: 45px !important;
    line-height: 50px;
    color: #fff;
    
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
}
div#About .txt .mwc-about-conr .trt ul {
    padding: 0;
    margin: 0;
    max-width: 500px;
}
div#About .txt .mwc-about-conr .trt ul li {
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
div#About .txt .mwc-about-conr .trt ul li:not(:last-child) {
    margin-bottom: 25px;
}
div#About .txt .mwc-about-conr .trt ul li p {
    font-size: 18px !important;
    line-height: 28px;
    color: #fff;
    
    font-weight: 400;
}
div#About .txt .mwc-about-conr .trt ul li i {
    font-size: 25px;
    margin-right: 10px;
    font-style: normal;
}
/*ABOUT*/

/*PILLARS*/
div#pillars {
    background: #000;
}
.pillar_head h2 {
    font-size: 45px !important;
    line-height: 45px;
    
    font-weight: bold!important;
    color: #fff;
    margin-bottom: 100px !important;
    text-shadow: rgba(192, 169, 226, 0.5) 2.65165px 2.65165px 0px, rgba(192, 169, 226, 0.3) 5.3033px 5.3033px 0px;
    text-align: center;
    text-transform: uppercase;
}
.pillar_boday {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.pillar_boday .blk:hover .txt{ background: #000;}
.pillar_boday .blk button {
    padding: 0;
    width: 100%;
}
.pillar_boday .blk {
    width: 33.3%;
    text-align: center;
    max-width: 100%;
}
.pillar_boday .blk .icon {
    width: 100%;
}
.pillar_boday .blk .txt {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px 50px;
}
.pillar_boday .blk .txt h4 {
    font-size: 23px;
    line-height: 32px;
    
    font-weight: 700;
    color: #8d42b0;
    text-align: center;
    margin: 0;
    text-transform: uppercase;
}
.pillar_boday .blk .txt p {
    text-align: center;
    font-size: 18px;
    line-height: 30px;
    
    font-weight: 500;
    color: #fff;
}
.pillar_boday .blk .txt span {
    display: block;
    margin: 15px 0;
    font-size: 30px;
}
.pillar_boday .blk .icon img {
    border-radius: 0!important;
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.pillar_boday .blk button {
    background: transparent!important;
    border: none!important;
    cursor: pointer;
}
.fade.pillar_modal.modal.show::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    backdrop-filter: blur(5px);
}
.pillar_modal .modal-header {
    border: none;
}
.pillar_modal .modal-body {
    padding: 0 35px 35px;
}
.pillar_modal .modal-body h4 {
    text-align: center;
    margin-bottom: 25px;
    font-size: 30px !important;
    line-height: 45px;
    
    font-weight: bold!important;
    color: #fff;
    text-transform: uppercase;
}
.pillar_modal .modal-body p {
    font-size: 18px;
    line-height: 26px;
    
    font-weight: 500;
    color: #fff;
    margin: 0;
    text-shadow: 1px 1px black;
}
/*PILLARS*/

/*SPEAKERS*/
div#Speakers {
    background: url(../public/assets/img/mwc-banner-3.png) no-repeat center #9966ac;
    background-size: cover;
}
.mwc-speakers-list {
    width: 100%;
    display: flex;
    align-items: inherit;
    justify-content: center;
    flex-wrap: wrap;
}
.spk_blk {
    width: 20%;
    margin-bottom: 30px;
    padding: 0 10px;
}
.spk_blk .mwc-speaker {
    border-radius: 0;
}
.row.col-md-12.mwc-btn-sec {
    margin-bottom: 30px;
}
.spk_blk .img img {
    width: 240px;
    max-width: 240px;
    max-height: 240px;
    min-height: 240px;
    min-width: 240px;
    object-fit: cover;
    object-position: top;
    border-radius: 10px;
    transition: transform 0.6s;
  transform-style: preserve-3d;
}
.spk_blk:hover img {
    transform: rotateY(360deg);
  }
div#Speakers > h2 {
    text-align: center;
    font-size: 45px !important;
    line-height: 45px;
    
    font-weight: bold!important;
    color: #fff;
    margin: 0 auto 40px !important;
    text-shadow: rgba(141, 66, 176, 0.5) 2.65165px 2.65165px 0px, rgba(141, 66, 176, 0.3) 5.3033px 5.3033px 0px;
}
.spk_blk .txt p {
    text-align: center;
    font-size: 16px;
    line-height: 26px;
    font-family: 'open sans';
    font-weight: 500;
    color: #fff;
    margin: 25px 0 0;
}
.mwc-btn-sec {
    margin-top: 0 !important;
}
.mwc-speakers .mwc-btn-sec button {
    font-size: 18px;
    line-height: 26px;
    
    font-weight: bold;
    color: #fff!important;
    text-transform: uppercase;
    letter-spacing: 5px;
    background: linear-gradient(45deg, #00b7ff, #c0a9e2, #8d42b0)!important;
    border: none!important;
    transition: 0.5s all ease-in-out;
    overflow: hidden;
    border-radius: 30px !important;
}
.mwc-speakers .btn-custom.btn-primary:hover, div#Speakers .mwc-speakers .btn-custom.btn-primary:focus {
    background: linear-gradient(45deg, #8d42b0,#00b7ff) !important;
}
.mwc-speaker .mwc-proname {
    text-decoration: underline;
}
.row.col-md-12.mwc-btn-sec._mbl_view {
    display: none;
}
/*SPEAKERS*/

/*AWARDS*/
div#awards {
    width: 100%;
    background: url(../public/assets/img/awards_banner.jpg) no-repeat center;
    background-size: cover;
}
div#awards h2 {
    text-align: center;
    font-size: 45px !important;
    line-height: 45px;
    
    font-weight: bold!important;
    color: #fff;
    margin-bottom: 100px !important;
    text-shadow: rgba(192, 169, 226, 0.5) 2.65165px 2.65165px 0px, rgba(192, 169, 226, 0.3) 5.3033px 5.3033px 0px;
    text-transform: uppercase;
}
div#awards .boxed .blk + span {
    font-size: 35px;
}
div#awards .boxed .blk {
    width: max-content;
    background: linear-gradient(45deg, #00b7ff, #c0a9e2, #8d42b0)!important;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin: 0 auto 100px;
}
div#awards .boxed .blk i {
    font-size: 40px;
}
div#awards .boxed p {
    font-size: 18px !important;
    line-height: 24px;
    
    font-weight: bold!important;
    color: #fff;
    letter-spacing: 5px;
    margin: 0 15px;
    text-transform: uppercase;
}
div#awards a.my-button.btn.btn-primary.btn-custom {
    font-size: 18px!important;
    line-height: 26px;
    font-family: 'Code Pro';
    font-weight: 500;
    color: #fff!important;
    letter-spacing: 5px;
    background: linear-gradient(45deg, #8d42b0, #00b7ff)!important;
    border: none!important;
    transition: 0.5s all ease-in-out;
}
div#awards a.my-button.btn.btn-primary.btn-custom:hover {
    background: linear-gradient(45deg, #00b7ff, #8d42b0) !important;
}
/*AWARDS*/

/*ARCHIVES*/
div#archived {
    width: 100%;
    background: url(http://localhost:3000/static/media/mwc-banner-3.9c370bbc67b37ce70ee6.png) no-repeat center #9966ac;
    background-size: cover;
}
div#archived h2 {
    text-align: center;
    font-size: 45px !important;
    line-height: 45px;
    
    font-weight: bold!important;
    color: #fff;
    margin-bottom: 100px !important;
    text-shadow: rgba(192, 169, 226, 0.5) 2.65165px 2.65165px 0px, rgba(192, 169, 226, 0.3) 5.3033px 5.3033px 0px;
    text-transform: uppercase;
}
div#archived .a_blk {
    width: 100%;
    padding: 0 40px;
}

div#archived .a_blk a {
    display: block;
    width: 100%;
    font-size: 22px;
    line-height: 26px;
    
    font-weight: 500;
    color: #fff!important;
    letter-spacing: 5px;
    background: linear-gradient(45deg, #8d42b0, #00b7ff)!important;
    padding: 30px 10px;
    margin-bottom: 15px;
    text-decoration: none;
}
div#archived .a_blk p {
    font-size: 15px;
    line-height: 25px;
    
    font-weight: 500;
    color: #fff!important;
    text-align: center;
    margin: 0;
}
div#archived .a_blk p bdi {
    display: block;
}
div#new_Sponsors {
    background: #fff;
}
div#archived .a_blk p span {
    display: block;
    margin-bottom: 20px;
}
/*ARCHIVES*/

/*PAST SPONSERS*/
div#new_Sponsors h2 {
    font-size: 45px !important;
    line-height: 45px;
    
    font-weight: bold!important;
    color: #8d42b0;
    text-transform: uppercase;
}
div#new_Sponsors .row > div {
    text-align: center;
}
div#new_Sponsors.mwc-sponsors img {
    filter: unset;
    max-width: 350px!important;
    max-height: 160px;
}
div#new_Sponsors button.btn.btn-primary.btn-custom {
    font-size: 18px;
    line-height: 26px;
    
    font-weight: 500;
    color: #fff!important;
    letter-spacing: 5px;
    background: linear-gradient(45deg, #00b7ff, #c0a9e2, #8d42b0)!important;
    padding: 30px 10px;
    border: none!important;
    transition: 0.5s all ease-in-out;
    border-radius: 30px!important;
}
div#new_Sponsors button.btn.btn-primary.btn-custom:hover {
    background: linear-gradient(45deg, #00b7ff, #8d42b0)!important;
}
.owl-theme .item, .owl-theme2 .item  {width: 100%;height: 100%;display: flex;align-items: center;justify-content: center;min-height: 160px;}
div#new_Sponsors .mbl_view {display: block; margin-bottom: 30px !important;}
.owl-carousel.owl-theme2.owl-loaded.owl-drag .owl-stage-outer {direction: ltr;}
/*PAST SPONSERS*/

/*CONTACT*/
div#contact {
    width: 100%;
    background: #9966ac;
}
div#contact h2 {
    font-size: 45px !important;
    line-height: 45px;
    
    font-weight: bold!important;
    color: #fff;
    margin-bottom: 30px!important;
    text-transform: uppercase;
    text-shadow: rgba(10, 23, 59, 0.5) 2.65165px 2.65165px 0px, rgba(10, 23, 59, 0.3) 5.3033px 5.3033px 0px;
}
div#contact p b {
    display: block;
}
div#contact h2 + span {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
}
div#contact p {
    text-align: center;
    font-size: 19px !important;
    line-height: 28px;
    
    font-weight: 400!important;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 30px;
}
div#contact form {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
}
div#contact form .frm {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
div#contact form .frm ._frm {
    width: 42%;
}
div#contact form .frm input::placeholder, div#contact form .frm textarea::placeholder {
    color: #000;
}
div#contact form .frm input {
    border: 3px solid #2a2826;
    border-radius: 15px;
    background: #fff;
    color: #000;
    font-size: 20px;
    line-height: 26px;
    
    font-weight: 500;
    padding: 25px 25px;
    width: 100%;
    box-shadow: 7px 7px 0px 0px #393735;
}
div#contact form .frm textarea {
    border: 5px solid #2a2826;
    border-radius: 50px;
    background: #fff;
    color: #000;
    font-size: 20px;
    line-height: 26px;
    
    font-weight: 500;
    padding: 25px 25px;
    box-shadow: 15px 15px 0px 0px #393735;
    min-height: 225px;
    width: 80%;
    margin: auto;
}
div#contact form .frm:last-child {
    justify-content: center;
    margin-top: 20px;
}
div#contact form .frm:last-child button {
    font-size: 20px;
    line-height: 26px;
    
    font-weight: bold;
    color: #000!important;
    letter-spacing: 5px;
    background: linear-gradient(45deg, #00b7ff, #c0a9e2, #8d42b0)!important;
    padding: 15px 10px;
    border: none!important;
    transition: 0.5s all ease-in-out;
    border-radius: 30px!important;
    text-transform: uppercase;
    width: 40%;
    min-width: 300px;
}
/*CONTACT*/


/*New changes*/
.mwc-banner img {
    height: 100%;
    object-fit: cover;
    object-position: top;
}
.mwc-banner {
    height: 920px;
}
.mwc-banner .mwc-con {
    padding-top: 250px;
}
.mwc-banner .mwc-con .yeartxt {
    justify-content: flex-end;
    padding-top:130px;
}
.mwc-banner .mwc-con h1 {
    padding-left: 0;
}
.btnbottomalign {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.mwc-banner .mwc-con h1 {
    font-size: 36px;
}
.mwc-banner .mwc-con h1 span {
    font-size: 22px;
    line-height: 30px;
    display: block;
    padding-top: 10px;
}
img.agnt_logo {
    width: 24px;
    height: 24px;
    object-fit: cover;
}
.footer-links.builtwallet {
    display: flex;
    align-items: self-start;
    justify-content: center;
}
.owl-theme3 .item {
    margin: auto;
}
.brownbgsec .owl-next {
    right: -20px !important;
}
.brownbgsec .owl-prev {
    left: -20px;
}

/*Data hackathon*/
div#fodh {
    width: 100%;
    background: url(../public/assets/img/Data_Hackathon.png) no-repeat right center;
    background-size: cover;
    padding: 150px 0;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
}
div#fodh h2 {
    font: 130px/130px NeueMachina-Regular !important;
    color: #fff;
}
div#fodh h2 span {
    display: block;
    color: #cbffff;
}

div#fodh p {
    color: #f0f0f0;
    font: 32px/40px NeueMachina-Regular !important;
    max-width: 860px;
    text-align: left;
    margin-bottom: 60px;
}
div#fodh button {
    color: #fff !important;
    border-color: #fff !important;
}
div#fodh button:hover, div#fodh button:focus {
    border-color: #febe98 !important;
}

/*Page*/
.data_hack header .mwc-navbar {
    padding: 15px 0 !important;
    transition: all ease 0.75s;
    background: transparent;
    font-family: "Unbounded", sans-serif;
    height: auto;
}
.data_hack header .mwc-navbar .navbar-brand span {
    color: #edf0f2;
    font: 20px/20px NeueMachina-Ultrabold !important;
}
.data_hack header .mwc-navbar li a, .data_hack header .mwc-navbar li button {
    color: #edf0f2 !important;
    font: 20px/20px Cy-Regular !important;
}
.data_hack header .mwc-navbar li a::before, .data_hack header .mwc-navbar li button::before {
    display: none;
}
.data_hack header .mwc-navbar li a:hover, .data_hack header .mwc-navbar li button:hover {
    color: #b3ffff !important;
}
.data_banner {
    width: 100%;
    margin-top: 100px;
    min-height: 700px;
    position: relative;
}
.ban_img img {
    width: 100%;
    object-fit: cover;
    min-height: 700px;
}
.ban_cnt {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.data_banner .ban_cnt h2 {
    color: transparent;
    font: 68px/60px NeueMachina-Ultrabold !important;
    letter-spacing: 2px;
    -webkit-text-stroke: 3px white;
    text-align: center;
    height: 60px;
    margin-bottom: 125px !important;
}
.data_banner .ban_cnt h2 a {
    color: transparent;
}
.data_banner .ban_cnt p {
    font: 26px/30px Halcom-Regular !important;
    color: #00ade6;
    text-transform: uppercase;
    text-align: center;
    margin: 0 !important;
}
.data_banner .ban_cnt p span {
    display: block;
}
.owl-animated-in {
    opacity: 0;animation-name: fadeInUp;-webkit-animation-name: fadeInUp;
    animation-duration: 1s;animation-fill-mode: both;-webkit-animation-duration: 1s;-webkit-animation-fill-mode: both;animation-delay: 1s;
}
.tpt .rft {
    max-width: 290px;
   z-index: 1;
   position: relative;
}
.tpt .rft img {
    width: 100%;
}

.winner {
    width: 100%;
    position: relative;
}
.winner::before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    position: absolute;
    left: 0;
    top: 0;
}
.cnt {
    width: 100%;
    padding: 100px 0;
    position: absolute;
    top: 0;
    left: 0;
}
video#video {
    height: 840px;
    width: 100%;
    object-fit: cover;
}
.winner p {
    text-align: center;
    margin-bottom: 90px;
    color: #fff;
    font: 28px/36px NeueMachina-Regular !important;
}
.winner p span {
    color: #b3ffff;
    font-family: NeueMachina-Ultrabold;
}
.winner h2 {
    font: 80px/80px NeueMachina-Regular !important;
    color: #b3ffff;
    text-shadow: 3px 0 #f0f;
    margin-bottom: 50px;
}
.winner h2 span {
    display: block;
}
.winner h4 {
    font: 24px/30px NeueMachina-Regular !important;
    color: #edf0f2;
    margin: 0;
}
.winner .btns {
    width: 100%;
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.winner .btns .btn {
    font: 21px/30px NeueMachina-Ultrabold !important;
    color: #edf0f2;
    text-transform: uppercase;
    border: 1px solid #b3ffff;
    width: 300px;
    height: 65px;
    transition: 0.5s all ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.winner .btns .btn:not(:last-child) {
    margin-right: 15px;
}
.winner .btns .btn:hover {
    background: #b3ffff;
    color: #000;
}
.agenda {
    width: 100%;
    padding: 175px 0 100px;
    background: #fff;
}
.agenda h2 {
    font: 80px/80px Halcom-Bold !important;
    color: #000;
}
.agenda h2 span {
    display: block;
    color: #b3ffff;
}
.agenda ul {
    padding: 0;
    margin: 0;
}
.agenda ul li h5 {
    font: 26px/30px Halcom-Bold !important;
}
.agenda ul li {
    list-style: none;
    color: #000;
    font: 20px/40px Halcom-Regular !important;
}
.agenda ul li:not(:last-child) {
    margin-bottom: 30px;
}
.agenda ul li a {
    color: #000;
    text-decoration-color: #b3ffff;
}
.agenda ul li span {
    color: #b3ffff;
    font-family: NeueMachina-Ultrabold ;
}
.agenda ul li span.borderd {
    color: #fff;
    font-family: NeueMachina-Regular;
    border: 1px solid #b3ffff;
    padding: 0px 10px 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.tpt {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sponsors {
    width: 100%;
    padding: 100px 0;
    background: url(../public/assets/img/spons_bg.png) no-repeat bottom;
    background-size: cover;
}
.sponsors .top {
    width: 100%;
    text-align: center;
    margin-bottom: 150px;
}
.sponsors .top h2 {
    font: 80px/80px NeueMachina-Regular !important;
    color: #edf0f2;
    margin-bottom: 15px !important;
}
.sponsors .top p {
    text-align: center;
    margin: 0;
    color: #fff;
    font: 20px/30px NeueMachina-Regular !important;
}
.sponsors .bdy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
.sponsors .bdy .slider {
    width: 100%;
}
.sponsors .bdy .slider .item img {
    border-radius: 30px;
}
.sponsors .bdy .btns .btn {
    font: 21px/30px NeueMachina-Ultrabold !important;
    color: #edf0f2;
    text-transform: uppercase;
    border: 1px solid #b3ffff;
    width: 300px;
    height: 65px;
    transition: 0.5s all ease-in-out;
}
.sponsors .bdy .btns .btn:hover {
    background: #b3ffff;
    color: #000;
}
.sponsors .bdy .btns {
    margin: 50px 0 0 auto;
}
.sponsors .fyt {
    width: 100%;
    padding-top: 80px;
    text-align: right;
}
.sponsors .fyt p {
    text-align: right;
    font: 40px/40px NeueMachina-Regular !important;
    color: #fff;
}
.faq {
    width: 100%;
    padding: 100px 0;
    background: #000;
}
.faq .bdy .flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.faq h2 {
    font: 80px/80px NeueMachina-Regular !important;
    color: #b3ffff;
    text-shadow: 3px 0 #f0f;
    margin-bottom: 50px;
    text-align: center;
}
.faq .bdy {
    width: 100%;
    position: relative;
}
.faq .bdy .lft .nav-pills .nav-item:not(:last-child) {
    margin-bottom: 15px;
}
.faq .bdy .lft .nav-pills .nav-item a {
    background: #fcff60;
    color: #000;
    font: 25px / 30px NeueMachina-Regular !important;
    border-radius: 0;
    opacity: 1;
    position: relative;
    padding: 10px 90px 10px 15px;
    width: max-content;
}
.faq .bdy .lft .nav-pills .nav-item a::after {
    content: "";
    border: 25px solid #000;
    position: absolute;
    right: 0;
    top: 0;
    border-top-color: transparent;
    border-left-color: transparent;
}
.faq .bdy .rft{
    width: 50%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 570px;
    padding-left: 15px;
}
.faq .bdy .rft .tab-content > .tab-pane p, .faq .bdy .rft .tab-content > .tab-pane ul li, .faq .bdy .accordion-item .accordion-body p, .faq .bdy .accordion-item .accordion-body ul li {
    color: #fcff60;
    font: 23px/30px NeueMachina-Regular !important;
}
.faq .bdy .rft .tab-content > .tab-pane p:not(:last-child) {
    margin-bottom: 20px;
}
.faq .bdy .rft .btns, .bdy._mob .btns {
    width: 100%;
    max-width: 300px;
    margin-left: auto;
}
.faq .bdy .rft .btns .btn:not(:last-child), .bdy._mob .btns .btn:not(:last-child)  {
    margin-bottom: 25px;
}
.faq .bdy .rft .btns .btn, .bdy._mob .btns .btn {
    font: 21px/30px NeueMachina-Ultrabold !important;
    color: #edf0f2;
    text-transform: uppercase;
    border: 1px solid #b3ffff;
    width: 300px;
    height: 65px;
    transition: 0.5s all ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.faq .bdy .rft .btns .btn:hover, .bdy._mob .btns .btn:hover {
    background: #b3ffff;
    color: #000;
}
.faq .bdy .rft .tab-content > .tab-pane p span, .faq .bdy .rft .tab-content > .tab-pane ul li span, .faq .bdy .accordion-item .accordion-body p span, .faq .bdy .accordion-item .accordion-body ul li span {
    color: #fff;
    font-family: NeueMachina-Ultrabold !important;
}
.faq .bdy .rft .tab-content > .tab-pane p a, .faq .bdy .accordion-item .accordion-body p a {
    color: #fff;
    text-decoration: underline;
}
.data_hack footer {
    background: #000;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: 0.5s all;
    border: 5px solid transparent;
}
.data_hack footer.highlighted {
    background: #29f3e2;
    transform: translateY(-10px);
    border-color: #041d3f;
}
.data_hack footer .ft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.data_hack footer .ft h4 {
    font: 15px/24px NeueMachina-Ultrabold !important;
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    padding-right: 220px;
    position: relative;
}
.data_hack footer .ft h4::after {
    content: "";
    width: 200px;
    height: 2px;
    background: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
}
.data_hack footer .ft .mwc-social-links a {
    color: #b3ffff;
}
.qus {
    width: 100%;
    padding: 120px 0 100px;
    background: #000;
}
.qus .nav-pills {
    justify-content: space-between;
}
.qus .nav-pills .nav-item { width: 30%; text-align: center; }

.qus .nav-pills a {
    font: 80px / 80px NeueMachina-Regular !important;
    color: #fff;
    text-shadow: 1px 0 #f0f;
    border: 1px solid #b3ffff;
    border-radius: 0px;
    transition: all ease 0.75s;
    position: relative;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.qus .nav-pills a.active {
    background: transparent;
    /* text-decoration: underline; */
    border-color: #fff;
    border-width: 3px;
    
}
.qus .tab-content .tab-pane {
    margin-top: 80px;
}
.qus .tab-content > .tab-pane p {
    font: 26px/32px NeueMachina-Regular !important;
    color: #b3ffff;
    text-align: left;
}
.qus .tab-content > .tab-pane p span {
    color: #fff;
    font-family: NeueMachina-Ultrabold !important;
    text-transform: uppercase;
}
.qus .tab-content > .tab-pane p:not(:last-child) {
    margin-bottom: 25px;
}
.data_hack .owl-carousel.owl-drag .owl-item {
    transition: 0.5s all ease-in-out;
}
.qus .tab-content > .tab-pane .title h4 {
    text-align: center;
    font: 20px/26px NeueMachina-Regular !important;
    color: #b3ffff;
    margin-bottom: 50px;
}
.qus .tab-content > .tab-pane .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.qus .tab-content > .tab-pane .box .img {
    width: 43%;
}
.qus .tab-content > .tab-pane .box .img img {
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.qus .tab-content > .tab-pane .box > div {
    width: 20%;
    position: relative;
}
.qus .tab-content > .tab-pane .box > div p {
    text-align: left;
    color: #fcff60;
    font-size: 16px !important;
    line-height: 24px !important;
}
.qus .tab-content > .tab-pane .box > div ul li {
    color: #fff;
    font: 16px/24px NeueMachina-Regular !important;
}
.qus .tab-content > .tab-pane .box > div h3 {
    font: 24px/24px NeueMachina-Ultrabold !important;
    color: #fff;
    position: absolute;
}
.qus .tab-content > .tab-pane .box > div > .top {
    margin-bottom: 80px;
}

.qus .tab-content > .tab-pane .box > .lft_txt h3 {
    margin: 0 !important;
    right: -40%;
    top: 50%;
    z-index: 1;
}
.qus .tab-content > .tab-pane .box > .lft_txt h3::before {
    content: "";
    width: 120px;
    height: 120px;
    background: url(../public/assets/img/circle.png) no-repeat center;
    background-size: contain;
    position: absolute;
    right: -113px;
    z-index: 1;
    top: -70px;
    animation: pulse-animation 1s infinite;
    animation-delay: 0.5s;
    border-radius: 100%;
}
.qus .tab-content > .tab-pane .box > .lft_txt h3::after {
    content: "";
    width: 240px;
    height: 40px;
    background: url(../public/assets/img/line.png) no-repeat center;
    background-size: contain;
    position: absolute;
    z-index: 1;
    right: -65px;
    top: -28px;
}
.qus .tab-content > .tab-pane .box > div > .top > h3 {
    margin: 0 !important;
    top: 130px;
    left: -200px;
}
.qus .tab-content > .tab-pane .box > div > .top > h3::before {
    content: "";
    width: 110px;
    height: 110px;
    background: url(../public/assets/img/circle.png) no-repeat center;
    background-size: contain;
    position: absolute;
    z-index: 1;
    left: -140px;
    top: -25px;
    animation: pulse-animation 1s infinite;
    animation-delay: 1.5s;
    border-radius: 100%;

}
.qus .tab-content > .tab-pane .box > div > .top > h3::after {
    content: "";
    width: 305px;
    height: 40px;
    background: url(../public/assets/img/line.png) no-repeat center;
    background-size: contain;
    position: absolute;
    z-index: 1;
    right: -65px;
    top: 15px;
}
.qus .tab-content > .tab-pane .box > div .btm {
    position: relative;
}
.qus .tab-content > .tab-pane .box > div .btm h3 {
    margin: 0 !important;
    left: -275px;
    top: 135px;
}
.qus .tab-content > .tab-pane .box > div .btm h3::after {
    content: "";
    width: 400px;
    height: 40px;
    background: url(../public/assets/img/line.png) no-repeat center;
    background-size: contain;
    position: absolute;
    z-index: 1;
    right: -180px;
    top: 30px;
}
.qus .tab-content > .tab-pane .box > div .btm h3::before {
    content: "";
    width: 155px;
    height: 155px;
    background: url(../public/assets/img/circle.png) no-repeat center;
    background-size: contain;
    position: absolute;
    z-index: 1;
    left: -190px;
    top: -25px;
    animation: pulse-animation 1s infinite;
    animation-delay: 2.5s;
    border-radius: 100%;
}
.faq .bdy .accordion-item > h2.accordion-header button {
    background: #fcff60 !important;
    color: #000;
    font: 25px / 30px NeueMachina-Regular !important;
    border-radius: 0;
    opacity: 1;
    position: relative;
    padding: 10px 90px 10px 15px;
    width: max-content;
    height: auto;
    text-transform: unset;
}
.faq .bdy .accordion-item > h2.accordion-header {
    position: relative;
    width: max-content;
}
.faq .bdy .accordion-item > h2.accordion-header::after {
    content: "";
    border: 25px solid #000;
    position: absolute;
    right: 0;
    top: 0;
    border-top-color: transparent;
    border-left-color: transparent;
    z-index: 10;
}
.faq .bdy .accordion-item:not(:last-child) {
    margin-bottom: 15px;
}
.bdy._mob {
    display: none;
}
@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(179, 255, 255, 0.5);
        transform: scale(1.1)
      }
    100% {
        box-shadow: 0 0 0 35px rgba(179, 255, 255, 0);
        transform: scale(1.)
    }
}
.qus .tab-content > .tab-pane .box .img h3 {
    display: none;
}
/*Page*/
/*Data hackathon*/

.commmunitywrap .commmunitycontent .mw845 > span {
    transition: 0.5s all ease-in-out;
}

.commmunitywrap .commmunitycontent .mw845 > span:hover {
    color: #ffccae;
}
.custom-navigation button.prev, .custom-navigation button.next {
    font-size: 75px !important;
    color: #fff !important;
    position: absolute;
    top: 35%;
    left: 0px;
    height: 65px;
    width: 24.53px;
    z-index: 1;
    transform: translateY(-50%);
    overflow: hidden;
    opacity: 0;
}
.speaker_slider {
    width: 100%;
    position: relative;
}
.custom-navigation button.prev {
    left: -20px;
}
.custom-navigation button.next {
    right: -20px;
}
.custom-navigation button.next {
    right: -20px;
    left: auto;
}
.btnwithtxt > h4 {
    font-size: 32px !important;
}

.all_spakers {
    width: 100%;
    text-align: center;
    padding-top: 50px;
}

div#about {
    padding: 100px 0;
    background: url(../public/assets/img/really.png) no-repeat center;
    background-size: cover;
    position: relative;
}
div#about::before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    position: absolute;
    top: 0;
    left: 0;
}
div#about h2 {
    color: #ffccae;
    margin-bottom: 100px !important;
    text-align: center;
    position: relative;
    z-index: 1;
}
div#about h2 span {
    color: #fff;
}
div#about ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
}
div#about ul li {
    list-style: none;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
}
div#about ul li .icns {
    width: 75px;
}
div#about ul li .icns img {
    width: 100%;
    object-fit: contain;
}
div#about ul li .txt {
    width: calc(100% - 75px);
    padding-left: 15px;
}
div#about ul li .txt h4 {
    font-size: 30px;
    line-height: 36px;
    color: #ffccae;
}
.speaker_lineup._mobile {
    display: none;
}
.ft_btns {
    width: 100%;
    padding: 50px 0;
}
.ft_btns .btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
div#fodh .data_btn {
    color: #fff !important;
    border-color: #fff !important;
}
div#fodh .data_btn:hover, div#fodh .data_btn:focus {
    background: transparent !important;
}
.data_banner .ban_cnt h2 a {
    color: transparent;
    -webkit-text-stroke: 3px white;
}

.asc-popup2 .modal-content {
    background: linear-gradient(40deg, #000000, #389595, #669393);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 15px 15px 10px rgba(0,0,0,.5);
    border: 1px solid #516565;
}
.asc-popup2 .modal-content h4 {
    font: 24px/30px NeueMachina-Regular !important;
    color: #fff;
}
.asc-popup2 .modal-content h4 + .btn {
    font: 14px / 30px NeueMachina-Ultrabold !important;
    color: #edf0f2;
    text-transform: uppercase;
    border: 1px solid #b3ffff;
    width: 300px;
    height: 65px;
    transition: 0.5s all ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    width: 100%;
    letter-spacing: 2px;
}
.asc-popup2 .modal-content button.clsbtn.btn.btn-primary {
    top: 0;
    right: 0;
    color: #fff !important;
}


.mwc-banner .mwc-con img {
    position: relative;
}

@keyframes my-animation {
	0% {
		filter: none;
	}
	20% {
		filter: url(#filter);
	}
	50% {
		filter: url(#filter-2);
	}
	80% {
		filter: url(#filter-3);
	}
	94% {
		filter: none;
	}
}

#my-image {
    transition: 0.5s all ease-in-out;
}

#my-image:hover {
	filter: url(#filter);
	animation: 0.8s my-animation alternate infinite;
}
.mwc-banner .mwc-con .yeartxt svg {
    width: 493.13px;
    height: 325px;
}
.faq .bdy .lft .nav-pills .nav-item a {
    overflow: hidden;
}
.tiwrap img:first-child {
    margin: 0 30px 0 0;
}
.tiwrap h4 {
    font: 30px/30px NeueMachina-Ultrabold !important;
    color: #000;
    margin: 0;
}
a.cmt_btn {
    color: #fffbf9 !important;
    text-decoration: none;
}

button.btn.disabled {
    opacity: 0.5 !important;
}
.commmunitywrap h2 {
    font-size: 60px !important;
    line-height: 70px;
    color: #ffccae;
    text-transform: uppercase;
}
.commmunitywrap p {
    font-size: 26px !important;
    line-height: 35px;
    color: #ffccae;
    max-width: 750px;
    position: relative;
    margin-bottom: 35px;
}
.commmunitywrap p span {
    color: #fff;
    font-size: 31px;
    line-height: 31px;
    cursor: pointer;
}
.hoverme .pop {
    opacity: 0;
    visibility: hidden;
    width: max-content;
    height: auto;
    background: #97504d;
    border-radius: 10px;
    z-index: -1;
    margin: auto;
    transition: all 0.3s ease;
    position: absolute;
    top: 0;
    left: 50%;
    padding: 15px;
}
@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

.hoverme .pop p {
    margin: 0;
    font-size: 22px !important;
}

.hoverme p span:hover + .pop {
    opacity: 1;
    visibility: visible;
  }
.commmunitywrap p:not(:last-child) {
    margin-bottom: 35px;
}
.hoverme .pop::before {
    content: "";
    border: 15px solid #97504d;
    position: absolute;
    top: 15px;
    left: -30px;
    border-left-color: transparent;
    border-top-color: transparent;
    border-bottom-color: transparent;
}
div#new_speaker .owl-theme3 .item {
    width: 100%;
}
div#new_speaker .psitemimg img {
    height: 300px;
    width: 240px !important;
    object-fit: cover;
    object-position: top;
}
div#new_speaker .psitemimg {
    width: auto;
    height: 300px;
}
div#new_speaker a.psitem h4 {
    font-size: 16px;
    line-height: 24px;
    word-break: break-word;
}
div#new_speaker .owl-next {
    right: -40px !important;
}
div#new_speaker .owl-prev {
    left: -40px;
}
.builtwallet .developlink:first-child > a {
    flex-direction: column;
}
.builtwallet .developlink:first-child > a img {
    width: 100%;
    object-fit: contain;
    max-width: 140px;
    margin: 2px 0 0;
}
.new-accor .accordion-header .accordion-button {
    padding-right: 60px;
    position: relative;
}
.new-accor .accordion-header .accordion-button::before, .new-accor .accordion-header .accordion-button::after {
    content: "";
    width: 25px;
    height: 3px;
    background: #fff;
    position: absolute;
    right: 0;
    transition: 0.5s all ease-in-out;
}
.new-accor .accordion-header .accordion-button::after {
    transform: rotate(0deg);
    filter: unset;
}
.new-accor .accordion-header .accordion-button.collapsed::after {
    transform: rotate(90deg);
}
.tpimgwrap + div {
    width: calc(100% - 225px);
}
div#new_speaker .owl-theme3 .item > a:not(:last-child) {
    margin-bottom: 40px;
    display: block;
    min-height: 435px;
}
div#new_speaker .owl-prev, div#new_speaker .owl-next {
    top: 50%;
    transform: translateY(-50%);
    height: auto;
}
.modal.display-block {
    position: absolute;
    left: 0;
    top: 95px;
    background: #200217;
    height: 100%;
    min-height: 825px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal.display-block h1 {
    color: #fff;
    margin: 0;
}
.ticket_info .accordion-header .accordion-button {
    font-size: 20px;
    line-height: 30px;
    height: 55px;
}
.ticket_info .accordion-body h4:not(:first-child) {
    margin-top: 30px;
}
.ticket_info {
    margin-bottom: 50px;
}
.ticket_info .accordion-header .accordion-button[aria-expanded="true"] {
    background: transparent !important;
}
div#ticket_info button {
    margin: auto;
}
.get_btn {
    text-align: center; 
}
.v-body p {
    font-family: "Playfair Display", serif;
    font-size: 24px !important;
    line-height: 36px;
    color: #ffccae;
    font-weight: 400 !important;
    padding-left: 20px;
    margin: 0;
}
.v-foot {
    width: 100%;
    padding-top: 40px;
}
.v-foot > .row > div {
    text-align: center;
}
.v-foot > .row > div p {
    text-align: center;
    font-family: "Playfair Display", serif;
    font-size: 24px !important;
    line-height: 36px;
    color: #ffccae;
    font-weight: 400 !important;
}
.v-foot > .row > div h4 {
    font-family: "Playfair Display", serif;
    font-size: 48px !important;
    color: #fffbf9;
    font-weight: 400 !important;
}
.v-head h4 {
    text-align: center;
}
.head_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1680px;
    margin: auto;
    position: relative;
}
.sub_logo img {
    max-width: 200px !important;
    object-fit: contain;
}
.sub_logo {
    position: absolute;
    bottom: -15px;
    left: 125px;
}
.mwc-navbar {
    height: 110px;
}
.sub_logo span {
    font-size: 14px;
    line-height: 26px;
    font-weight: bold;
    background: transparent;
    border: none;
    color: #febe98 !important;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #febe98;
}
.innerheader .carousel-inner .carousel-item > img {min-height: 450px;}
/*New changes*/

/*Modal Form*/
.fade.asc-popup.modal .modal-dialog {
    max-width: 700px;
}
.fade.asc-popup.modal .modal-dialog .modal-content {
    background: #2e2020;
    border-top: 3px solid #6d4f44;
}
.fade.asc-popup.modal .modal-dialog .modal-content #mc_embed_signup .clsbtn.btn.btn-primary {
    background: #2e2020 !important;
    color: #febe98!important;
    right: -25px;
    top: -30px;
}
.asc-popup #mc_embed_signup h2 {
    font-size: 30px !important;
    margin-bottom: 20px !important;
}
.asc-popup #mc_embed_signup h4 {
    margin-bottom: 15px;
}
.asc-popup #mc_embed_signup h2 + h4 {
    color: #febe98;
}
#mc_embed_signup .mc-field-group p {
    color: rgba(255, 255, 255, 0.7);
    font-family: Playfair Display, serif;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 24px;
    font-family: "Unbounded", sans-serif;
    margin-bottom: 10px;
}
._frms > .mc-field-group {
    width: 48% !important;
}
._frm, ._frms {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
._frm > div {
    width: auto !important;
}
._frm2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.asc-popup #mc_embed_signup .mc-field-group label {
    color: #ffccae;
    font-family: "Unbounded", sans-serif;
    font-size: 12px;
}
.asc-popup #mc_embed_signup .mc-field-group input {
    border-color: #6d544b;
}
.asc-popup #mc_embed_signup .mc-field-group input:focus {
    border-color: #ffccae;
}
.asc-popup #mc_embed_signup .mc-field-group input:before {
    background: transparent;
}
._frm > div > label {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
}
._frm > div > label > input[type="checkbox"], ._frm2 > div > label > input[type="checkbox"] {
    padding: 0 !important;
    height: initial !important;
    width: initial !important;
    margin-bottom: 0 !important;
    display: none !important;
    cursor: pointer !important;
}
._frm2 > div > label {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
}
._frm2 > div > label > span {
    margin-left: 5px;
}
._frm2 > div:not(:last-child) {
    margin-right: 20px;
}
#mc_embed_signup .button {
    background-color: transparent;
    color: rgb(254, 190, 152);
    border: 3px solid rgb(254,190,152);
    box-shadow: none;
    border-radius: 0;
    font-weight: 700;
    font-size: 16px;
    min-width: 150px;
    font-family: "Unbounded", sans-serif;
}
#mc_embed_signup .button:hover {
    color: #fff;
    background: rgb(254,190,152);
    outline: none !important;
}
#mc_embed_signup .mc-field-group ul li {
    color: rgba(255, 255, 255, 0.7);
    font-family: Playfair Display, serif;
    font-size: 13px !important;
    font-weight: 400 !important;
    line-height: 24px;
    font-family: "Unbounded", sans-serif;
}
#mc_embed_signup .mc-field-group ul li:not(:last-child) {
    margin-bottom: 10px;
}
._frm > div > label > span, ._frm2 > div > label > span {
    position: relative;
    cursor: pointer;
}
._frm > div > label > input[type="checkbox"]:checked + span:after, ._frm2 > div > label > input[type="checkbox"]:checked + span:after {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 6px;
    height: 12px;
    border: solid #d9ad94;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
._frm > div > label > span::before, ._frm2 > div > label > span, ._frm > div > label > span {
    padding: 10px 35px 10px 10px;
    box-shadow: rgba(255, 204, 174, 0.6) 0px 0px 0px 1px inset;
    border-radius: 2px;
    color: rgba(255, 255, 255, 0.7);
}
._frm2 > div > label > input[type="checkbox"]:checked + span:after {
    top: 10px;
    left: auto;
    right: 10px;
}
.fade.asc-popup.modal .modal-dialog .modal-content .modal-body {
    padding: 30px 30px 15px;
}
#mc_embed_signup form {
    margin: 0;
}
.fade.asc-popup.modal .modal-dialog .modal-content #mc_embed_signup button.clsbtn.clsbtn2.btn.btn-primary {
    top: -70px;
}
.asc-popup #mc_embed_signup h4 {
    line-height: 30px;
    font-family: "Unbounded", sans-serif;
    font-size: 14px;
}
.pay-error h4 {
    margin-bottom: 0 !important;
    margin-top: 15px;
    color: red;
    font-weight: bold;
    background: rgb(254, 190, 152) !important;
    text-transform: none;
}
.frm_blk {
    display: flex;
    align-items: initial;
    justify-content: space-between;
}
.frm_blk > ul {
    width: 49%;
    background: #3d2c2c;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    margin: 0 0 5px;
}
#mc_embed_signup .clear {
    margin-bottom: 0 !important;
}
._frm2 > div > label > input[type="checkbox"]:checked + span, ._frm > div > label > input[type="checkbox"]:checked + span {
    color: #ffccae;
}
.pop_scroll {
    max-height: 888px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 10px;
    margin-bottom: 20px;
}
.fade.asc-popup.modal .modal-dialog {
    max-height: 90%;
}
.fade.asc-popup.modal.show {
    padding: 0 !important;
}
.fade.asc-popup.modal .modal-dialog .modal-content {
    height: 100%;
    max-height: 100%;
}
 
.pop_scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px #6d4f44;
	background-color: rgb(254, 190, 152);
    border-radius: 10px;
}

.pop_scroll::-webkit-scrollbar
{
	width: 3px;
	background-color: rgb(254, 190, 152);
    border-radius: 10px;
}

.pop_scroll::-webkit-scrollbar-thumb
{
	background-color: #6d4f44;
}
/*Modal Form*/


/*Data Hackathon New*/
.data_hack .navbar-nav li:not(:first-child) {
    margin-left: 10px;
}
.data_hack header .mwc-navbar li button {
    padding: 10px 20px !important;
    font-size: 20px !important;
    font-family: Halcom-Bold !important;
    color: #fff !important;
}
.data_hack header .mwc-navbar li button.nav-link.reg_btn {
    background: rgb(4, 29, 63);
    color: #29f3e2 !important;
    border-radius: 15px;
    border: 2px solid rgb(4, 29, 63);
}
.data_hack header .mwc-navbar li button.nav-link.reg_btn:hover {
    background: transparent;
    color: rgb(4, 29, 63) !important;
}
.data_hack header .mwc-navbar li button.nav-link.spn_btn {
    background: #29f3e2;
    color: rgb(4, 29, 63) !important;
    border-radius: 15px;
    border: 2px solid #29f3e2;
}
.data_hack header .mwc-navbar li button.nav-link.spn_btn:hover {
    background: transparent;
    color: #29f3e2 !important;
}
.data_banner .ban_cnt h2 {
    color: #fff;
    font: 75px / 85px Flatory-Light !important;
    text-align: left;
    height: auto;
    margin-bottom: 15px !important;
    max-width: 735px;
    text-transform: uppercase;
    letter-spacing: 5px;
    text-shadow: 0 0 black;
}
.data_banner .ban_cnt h2 span {
    display: block;
}
.flx {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.flx > img {
    width: 200px;
}
.data_banner .ban_cnt p {
    line-height: 36px !important;
    text-align: left;
}
.flx button {
    background: url(../public/assets/img/data_hack2024/calender_icon.png) no-repeat left 5px center #29f3e2;
    color: #141414 !important;
    border-radius: 10px;
    width: auto;
    font: 16px / 26px Halcom-Medium !important;
    padding: 10px 10px 10px 35px;
    text-transform: uppercase;
    border-color: transparent;
    border: 2px solid #29f3e2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 25px;
    position: relative;
}
.flx button img {
    margin-right: 10px;
}
.flx button:hover {
    background: url(../public/assets/img/data_hack2024/calender_icon_hover.png) no-repeat left 5px center transparent;
    background-size: 25px;
    color: #29f3e2 !important;
}
.dt_future {
    width: 100%;
    padding: 50px 0;
    background: #fff;
}
.htd h2 {
    text-align: center;
    color: #041d3f;
    text-transform: uppercase;
    font-size: 72px !important;
    line-height: 77px !important;
    margin-bottom: 75px !important;
    font-family: Inter-Bold !important;
}
.bdy {
    display: table;
}
.bdy .img {
    float: right;
    width: 50%;
}
.bdy .img img {
    width: 100%;
    object-fit: contain;
    padding-left: 50px;
}
.dt_future .bdy .txt > p:first-child {
    margin-bottom: 60px;
}
.dt_future .bdy .txt p {
    color: #041d3f;
    font: 24px / 36px Halcom-Medium !important;
    text-align: left;
    margin-bottom: 0px;
}
.dt_future .bdy .txt h4 {
    color: #041d3f;
    font: 30px / 36px Halcom-Bold !important;
    margin-bottom: 25px;
}
.tracks_chag .bdy .txt h4 {
    color: #141414;
    font: 30px / 36px Halcom-Bold !important;
    margin-bottom: 25px;
}
.tracks_chag .bdy .txt p {
    color: #141414;
    font: 24px / 36px Halcom-Regular !important;
    text-align: left;
    margin-bottom: 0px;
}
.tracks_chag .bdy .txt ul li {
    list-style: none;
    color: #141414;
    font: 26px / 36px Halcom-Regular !important;
    margin-bottom: 0px;
}
.bdy .txt ul {
    padding: 0;
}
.dt_future .bdy .txt ul li {
    list-style: none;
    color: #041d3f;
    font: 26px / 36px Halcom-Regular !important;
    margin-bottom: 0px;
}
.bdy .txt ul li span {
    font-family: Halcom-Bold !important;
    padding-right: 10px;
}
.bdy .txt ul + p {
    margin: 0;
    font-size: 20px !important;
}
.dt_future .btns {
    width: 50%;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.dt_future .btns .btn {
    width: 47%;
    background: #041d3f;
    color: #29f3e2 !important;
    font: 26px / 30px Halcom-Medium !important;
    padding: 30px 15px;
    text-transform: uppercase;
    border: 5px solid #041d3f !important;
    border-radius: 15px !important;
}
.dt_future .btns .btn:hover {
    background: transparent;
    color: #041d3f !important;
}
.ban_img img {
    max-height: 700px;
}
.abt_sec, .sub_sec {
    width: 100%;
    padding: 50px 0;
    background: #fff;
}
.sub_sec {
    padding-bottom: 0;
}
.sub_sec2 h2 {
    color: #29f3e2;
    font: 80px / 80px BodoniCondC !important;
    letter-spacing: 2px;
    margin-bottom: 20px !important;
}
.sub_sec2 h2 span {
    font-family: Halcom-Medium !important;
    font-size: 55px !important;
    background: #041d3f;
    padding: 10px 15px;
    border-radius: 15px;
}
.sub_sec2 p {
    margin: 0;
    font-family: Halcom-Medium !important;
    font-size: 20px !important;
    color: #fff !important;
}
.sub_sec2 p span {
    text-transform: uppercase;
}
.abt_sec h2, .tracks_chag h2, .resource h2 {
    text-align: left;
    color: #141414;
    font-size: 72px !important;
    line-height: 76px !important;
    margin-bottom: 60px !important;
    font-family: Cy-ExtraBold  !important;
}
.abt_sec .blk {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.abt_sec .blk > div {
    width: 43%;
}
.abt_sec .blk > div h4 {
    color: #141414;
    font: 40px / 40px Halcom-Bold !important;
    margin-bottom: 10px;
}
.abt_sec .blk > div p {
    font: 24px / 34px Halcom-Regular !important;
    color: #141414;
    text-align: left;
    margin-bottom: 0;
}
.text_sec {
    background: #fcff60;
}
.text_sec, .data_agenda, .reg_sec {
    width: 100%;
    padding: 50px 0;
}
.text_sec p {
    text-align: center;
    font: 24px / 34px Halcom-Regular !important;
    color: #141414;
    margin-bottom: 0;
}
.tracks_chag {
    background: #fff;
}
.sft_box, .resource, .jud_sec, .price_cate, .spon_sec {
    padding: 50px 0;
}
.tracks_chag p {
    font: 24px / 30px Halcom-Regular !important;
    color: #141414;
    margin-bottom: 0;
}
.tracks_chag .bdy {
    position: relative;
    width: 100%;
}
.tracks_chag .bdy .img {
    float: none;
    width: 65%;
    position: absolute;
    right: 0;
    top: 0;
}
.tracks_chag .bdy .txt {
    height: 1100px;
    position: relative;
    z-index: 1;
}
.tracks_chag .bdy .txt .blks h4, .tracks_chag .bdy .txt .blks p, .tracks_chag .bdy .txt .blks ul {
    margin: 0;
}
.tracks_chag .bdy .txt .blks {
    max-width: 500px;
}
.tracks_chag .bdy .txt .blks ul li {
    list-style: disc;
    list-style-position: inside;
    font-size: 24px !important;
}
.tracks_chag .bdy .txt .blks:first-child {
    position: absolute;
    left: 15%;
    top: 7%;
}
.tracks_chag .bdy .txt .blks:last-child {
    position: absolute;
    bottom: 5%;
    right: 23%;
}
.tracks_chag .bdy .txt .blks:nth-child(2) {
    position: absolute;
    left: 0;
    top: 36%;
}
.tracks_chag .bdy .txt .blks:first-child::before {
    content: "";
    width: 80%;
    height: 4px;
    background: #141414;
    position: absolute;
    right: -80px;
    top: 25px;
}
.tracks_chag .bdy .txt .blks:first-child::after {
    content: "";
    width: 50px;
    height: 4px;
    background: #141414;
    position: absolute;
    right: -122px;
    top: 42px;
    transform: rotate(45deg);
}
.tracks_chag .bdy .txt .blks:last-child::before {
    content: "";
    width: 79%;
    height: 4px;
    background: #141414;
    position: absolute;
    right: -55px;
    top: 25px;
}
.tracks_chag .bdy .txt .blks:last-child::after {
    content: "";
    width: 30px;
    height: 4px;
    background: #141414;
    position: absolute;
    right: -80px;
    top: 35px;
    transform: rotate(45deg);
}
.tracks_chag .bdy .txt .blks:nth-child(2)::before {
    content: "";
    width: 130%;
    height: 4px;
    background: #141414;
    position: absolute;
    right: -360px;
    top: 35px;
}
.tracks_chag .bdy .txt .blks:nth-child(2)::after {
    content: "";
    width: 30px;
    height: 4px;
    background: #141414;
    position: absolute;
    right: -385px;
    top: 45px;
    transform: rotate(45deg);
}
.tracks_chag .bdy > p {
    font-family: Halcom-Medium !important;
    color: #00ade6;
}
.sft_box {
    background: #fff;
}
.sft_box h2 {
    color: #041d3f;
    font: 80px / 80px BodoniCondC !important;
    letter-spacing: 2px;
    margin-bottom: 20px !important;
}
.sft_box .d-flex {
    align-items: inherit;
    justify-content: space-between;
}
.sft_box .d-flex .box {
    width: 50%;
    background: 3fff;
    padding: 50px 70px 15px;
}
.sft_box .d-flex .box:first-child {
    border-right: 5px solid #041d3f;
}
.sft_box .d-flex .box h4 {
    color: #29f3e2;
    font: 36px / 42px Halcom-Bold !important;
    margin-bottom: 20px;
}
.sft_box .d-flex .box p{
    color: #000;
    font: 22px / 32px Inter-Regular !important;
    text-align: left;
    font-style: italic !important;
}
.sft_box .d-flex .box .img {
    text-align: right;
}
.sft_box .d-flex .box .img img {
    max-width: 110px;
    object-fit: contain;
}
.scdle {
    width: 100%;
    padding: 50px 0;
    background: #000;
}
.scdle h2 {
    color: #29f3e2;
    font: 80px / 80px BodoniCondC !important;
    letter-spacing: 2px;
    margin-bottom: 20px !important;
}
.scdle h5 {
    color: #fff;
    font: 28px / 38px Halcom-Medium !important;
}
.scdle p {
    color: #fff;
    font: 22px / 28px Halcom-Regular !important;
    text-align: left;
    margin: 0;
    padding-left: 50px;
}
.data_agenda {
    background: #00ade6;
    padding: 135px 0 50px;
    margin-top: 100px;
}
.data_hack {
    background: #fff;
}
.agenda_sec {
    display: table;
}
.agenda_sec .img {
    float: right;
    width: 40%;
    margin-top: -195px;
    margin-left: 50px;
    margin-bottom: 50px;
}
.agenda_sec .img img {
    width: 100%;
    object-fit: contain;
}
.agenda_sec .txt h3 {
    text-align: left;
    color: #edeff1;
    font-size: 46px !important;
    line-height: 50px !important;
    margin-bottom: 35px !important;
    font-family: Halcom-Bold !important;
}
.agenda_sec .txt .blks {
    padding-left: 50px;
}
.agenda_sec .txt .blks:not(:last-child) {
    margin-bottom: 50px;
}
.agenda_sec .txt .blks h5 {
    color: #edeff1;
    font: 26px / 36px Halcom-Medium !important;
}
.agenda_sec .txt .blks p {
    color: #edeff1;
    font: 18px / 28px Halcom-Regular !important;
    text-align: left;
    margin-bottom: 0 !important;
}
.resource {
    background: #000;
    border-bottom: 1px solid #fff;
}
.resource h2 {
    color: #29f3e2;
    font: 80px/80px BodoniCondC !important;
    letter-spacing: 2px;
    margin-bottom: 20px !important;
}
.resource .txt, .scdle .txt {
    padding-left: 125px;
}
.resource h4 {
    text-align: left;
    color: #fff;
    font-size: 36px !important;
    line-height: 40px !important;
    margin-bottom: 35px !important;
    font-family: Halcom-Medium !important;
}
.resource p {
    color: #fff;
    font: 22px / 30px Halcom-Regular !important;
    text-align: left;
    margin-bottom: 30px;
}
.resource h5 {
    color: #fff;
    font: 26px / 36px Halcom-Medium !important;
    text-align: left;
    margin-bottom: 15px;
}
.resource ul li {
    color: #fff;
    font: 22px / 30px Halcom-Regular !important;
    text-align: left;
}
.resource ul li:not(:last-child) {
    margin-bottom: 15px;
}
.in_prices {
    background: #041d3f;
    text-align: center;
}
.in_prices, .wht_build, .faq_sec, .linked_btns {
    padding: 50px 0;
}
.in_prices h2 {
    text-align: center;
    color: #29f3e2;
    text-transform: uppercase;
    font-size: 110px !important;
    line-height: 110px !important;
    margin-bottom: 35px !important;
    font-family: Inter-Bold !important;
}
.in_prices h3 {
    color: #29f3e2;
    font: 80px / 80px BodoniCondC !important;
    letter-spacing: 2px;
    margin-bottom: 20px !important;
}
.in_prices a {
    text-decoration: none;
    font: 30px/30px Halcom-Bold !important;
    color: #041d3f;
    background: #29f3e2;
    border: 5px solid #29f3e2;
    border-radius: 15px;
    padding: 10px 15px;
    display: inline-block;
}
.in_prices a span {
    text-transform: uppercase;
}
.in_prices a:hover {
    background: transparent;
    color: #29f3e2;
}
.wht_build {
    background: #edeff1;
}
.wht_build h3 {
    text-align: left;
    color: #141414;
    font-size: 70px !important;
    line-height: 70px !important;
    margin-bottom: 35px !important;
    font-family: Cy-ExtraBold !important;
}
.wht_build h4 {
    color: #141414;
    font: 36px / 36px Halcom-Medium !important;
    text-align: left;
    margin-bottom: 25px;
}
.wht_build h5 {
    color: #141414;
    font: 24px / 36px Halcom-Medium !important;
    text-align: left;
    margin-bottom: 15px;
}
.wht_build p {
    color: #141414;
    font: 20px / 28px Halcom-Regular !important;
    text-align: left;
    padding: 30px 0 0 50px;
}
.img_sec {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 100px 0 0;
}
.img_sec ._ig {
    width: 30%;
}
.img_sec ._ig img {
    width: 100%;
    object-fit: contain;
}
.wht_build .bks {
    width: 100%;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.wht_build .bks > div {
    width: 50%;
}
.wht_build .bks .img img {
    width: 100%;
    object-fit: contain;
}
.wht_build .bks .txt {
    padding-left: 50px;
}
.wht_build .bks .txt h3 {
    font-size: 36px !important;
    margin-bottom: 25px !important;
    font-family: Halcom-Bold !important;
}
.wht_build .bks .txt ul li {
    color: #141414;
    font: 22px / 28px Halcom-Regular !important;
    text-align: left;
}
.wht_build .bks:last-child > .txt {
    padding: 0 50px 0 0;
}
.wht_build .bks:last-child > .txt > ul {
    margin-bottom: 50px;
}
.sub_sec .head {
    margin-bottom: 50px;
}
.sub_sec .head h2 {
    text-align: left;
    color: #141414;
    font-size: 72px !important;
    line-height: 76px !important;
    margin-bottom: 35px !important;
    font-family: Cy-ExtraBold !important;
}
.sub_sec .head h2 span {
    font-size: 36px !important;
    font-family: Halcom-Bold !important;
}
.sub_sec .head p {
    margin: 0;
    color: #141414;
    font: 26px / 36px Halcom-Regular !important;
    text-align: left;
    max-width: 970px;
}
.sub_sec .bdys {
    display: flex;
    align-items: inherit;
    justify-content: space-between;
    flex-direction: column;
}
.sub_sec .bdys .boxs {
    width: 100%;
}
.sub_sec .bdys .boxs > h3 {
    text-align: right;
    color: #141414;
    font-size: 30px !important;
    line-height: 36px !important;
    margin-bottom: 10px !important;
    font-family: Halcom-Bold !important;
}
.sub_sec .bdys .boxs > h3 img {
    width: 35px;
    margin-right: 15px;
}
.sub_sec .bdys .boxs .box {
    width: 100%;
    background: #141414;
    padding: 50px;
    height: calc(100% - 36px);
}
.sub_sec .bdys .boxs:last-child .box {
    background: #00ade6;
}
.sub_sec .bdys .boxs .box .bks {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}
.sub_sec .bdys .boxs .box .bks:not(:last-child) {
    margin-bottom: 20px;
}
.sub_sec .bdys .boxs .box .bks .icon {
    width: 60px;
}
.sub_sec .bdys .boxs .box .bks .icon img {
    width: 100%;
    object-fit: contain;
}
.sub_sec .bdys .boxs .box .bks .txt {
    width: calc(100% - 60px);
    padding-left: 15px;
}
.sub_sec .bdys .boxs .box .bks .txt h4 {
    color: #edeff1;
    font: 24px / 30px Halcom-Medium !important;
    text-align: left;
    margin-bottom: 15px;
}
.sub_sec .bdys .boxs .box .bks .txt ul li {
    color: #edeff1;
    font: 16px / 26px Halcom-Regular !important;
    text-align: left;
}
.sub_sec .bdys .boxs:nth-child(2) h3 img {
    margin-left: 15px;
    margin-right: 0;
}
.sub_sec .bdys .boxs:first-child {
    margin-bottom: 50px;
}
.sub_sec .bdys .boxs:last-child h3 {
    text-align: left;
    color: #00ade6 !important;
}
.price_cate {
    text-align: center;
    background: #edeff1;
}
.price_cate h2 {
    color: #141414;
    font-size: 90px !important;
    line-height: 90px !important;
    margin-bottom: 75px !important;
    font-family: Cy-ExtraBold !important;
}
.price_cate a {
    background: #141414;
    color: #edeff1 !important;
    font: 26px / 30px Halcom-Bold !important;
    padding: 30px 50px;
    border: none;
    border: 5px solid #141414;
    display: inline-block;
    text-decoration: none;
}
.price_cate a:hover {
    background: transparent !important;
    color: #141414 !important;
}
.jud_sec {
    background: #edeff1;
}
.jud_sec .d-flex {
    justify-content: space-between;
}
.jud_sec .d-flex .img {
    width: 60%;
}
.jud_sec .d-flex .img img {
    width: 100%;
    object-fit: contain;
}
.jud_sec .d-flex .txt {
    width: 40%;
}
.jud_sec .d-flex .txt h3 {
    font-size: 40px !important;
    margin-bottom: 25px !important;
    color: #141414;
    font-family: Halcom-Bold !important;
}
.jud_sec .d-flex .txt h4 {
    font-size: 26px !important;
    color: #141414;
    font-family: Halcom-Medium !important;
}
.jud_sec .d-flex .txt p {
    color: #141414;
    font: 18px / 26px Halcom-Regular !important;
}
.reg_sec {
    text-align: center;
    background: #141414;
}
.reg_sec h2 {
    color: #edeff1;
    font-size: 130px !important;
    line-height: 130px !important;
    margin-bottom: 35px !important;
    font-family: Cy-ExtraBold !important;
    text-transform: uppercase;
}
.reg_sec p {
    text-align: center;
    font-size: 30px !important;
    margin-bottom: 75px !important;
    color: #edeff1;
    font-family: Halcom-Regular !important;
    text-transform: uppercase;
}
.reg_sec button {
    background: #fcff60;
    color: #141414 !important;
    font: 26px / 30px Halcom-Bold !important;
    padding: 30px 50px;
    border: none;
    text-transform: uppercase;
    border: 5px solid #fcff60;
}
.reg_sec button:hover {
    background: transparent;
    color: #fcff60 !important;
}
.reg_sec .blks {
    display: flex;
    align-items: inherit;
    justify-content: space-between;
    margin-top: 50px;
}
.reg_sec .blks > div {
    width: 48%;
}
.reg_sec .blks > .img img {
    width: 100%;
    object-fit: contain;
}
.reg_sec .blks > .txt {
    background: #00ade6;
    padding: 30px;
}
.reg_sec .blks > .txt p {
    text-align: left;
    font-size: 26px !important;
    line-height: 32px;
    margin-bottom: 25px !important;
    text-transform: math-auto;
}
.faq_sec {
    background: #141414;
}
.faq_sec > div > h2 {
    font-size: 45px !important;
    line-height: 45px !important;
    font-family: Halcom-Bold !important;
    text-transform: uppercase;
    padding: 10px 25px;
    margin-bottom: 25px !important;
    color: #00FFFF !important;
}
.faq_sec > div > h2 img {
    width: 40px;
}
.faq_sec .faq-accor .accordion-item:not(:last-child) {
    margin-bottom: 5px;
}
.faq_sec .faq-accor .accordion-item h2 button::after {
    display: none;
}
.faq_sec .faq-accor .accordion-item h2 button {
    background: #041d3f;
    font-size: 24px !important;
    color: #fff !important;
    font-family: Halcom-Regular !important;
    text-transform: capitalize;
    font-weight: normal !important;
}
.accordion-body p, .accordion-body {
    font-family: Halcom-Regular !important;
}
.faq_sec .accordion-header .accordion-button[aria-expanded="true"] {
    background: #041d3f !important;
}
.faq_sec .accordion-body p, .accordion-body {
    color: #00FFFF;
}
.spon_sec {
    background: #fff;
}
.spon_sec h2 {
    text-align: left;
    color: #141414;
    font-size: 72px !important;
    line-height: 72px !important;
    margin-bottom: 35px !important;
    font-family: Cy-ExtraBold !important;
}
.spon_sec p {
    text-align: left;
    font-size: 26px !important;
    line-height: 30px;
    margin: 0 !important;
    color: #141414;
    font-family: Halcom-Regular !important;
}
.spon_sec .spn_blk {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.spon_sec .spn_blk .logo img {
    width: 100%;
    object-fit: contain;
}
.spon_sec .spn_blk .logo {
    width: 31%;
}
.spon_sec .spn_blk:nth-child(2) .logo {
    width: 23%;
}
.spon_sec h5 {
    font-size: 30px !important;
    color: #141414;
    font-family: Halcom-Regular !important;
    margin-bottom: 0 !important;
}
.linked_btns {
    background: #fff;
}
.linked_btns .d-flex {
    align-items: center;
    justify-content: space-between;
}
.linked_btns .d-flex .btn {
    background: #041d3f;
    color: #29f3e2 !important;
    font: 30px / 30px Halcom-Bold !important;
    padding: 20px 50px;
    border: none;
    border: 5px solid #041d3f;
    border-radius: 15px;
    text-transform: uppercase;
}
.linked_btns .d-flex .btn:hover {
    background: transparent;
    color: #041d3f !important;
}
.data_hack footer {
    height: auto;
    padding: 50px 0;
}
.data_hack footer h2 {
    text-align: left;
    color: #fff;
    font-size: 65px !important;
    line-height: 65px !important;
    margin-bottom: 35px !important;
    font-family: Cy-ExtraBold !important;
}
.data_hack footer ul li {
    list-style: none;
    font-size: 26px !important;
    line-height: 46px;
    color: #fff;
    font-family: Halcom-Regular !important;
}
.data_hack footer ul {
    padding: 0;
}
.social_icons ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.data_hack footer .social_icons ul li:not(:last-child) {
    margin-right: 25px;
}
.data_hack footer .social_icons ul li a {
    color: #fff;
    font-size: 60px;
    transition: 0.5s all ease-in-out;
}
.data_hack footer .social_icons ul li a:hover {
    color: #29f3e2;
}
.social_icons {
    margin-top: 35px;
}
.data_hack footer {
    background: #141414;
}
.data_hack .mwc-navbar .navbar-brand img {
    height: 85px;
}
.data_hack .sub_logo  {
    position: relative;
    left: unset;
    bottom: unset;
    padding-left: 60px;
    margin-top: -15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.data_hack .sub_logo span {
    display: block;
    font-family: Inter-Light;
    color: #ffccae !important;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
}
.data_hack .mwc-navbar .sub_logo img {
    height: 25px;
}
.logo_s {
    padding-left: 5px;
    margin-top: -5px;
}
.data_hack header .mwc-navbar .container {
    align-items: flex-start;
} 
.data_hack header .navbar-expand-lg .navbar-collapse {
    padding-top: 25px;
}
.data_banner {
    margin-top: 0;
    min-height: 700px;
    max-height: 700px;
    overflow: hidden;
}
.data_hack header .mwc-navbar.mwc-fillbg1 {
    background: rgb(20 20 20);
}
.data_hack header .mwc-navbar.mwc-fillbg1 li button {
    color: #edeff1 !important;
}
.data_hack header .mwc-navbar li .dropdown-menu li a {
    color: #000 !important;
    font-size: 16px !important;
    font-family: Halcom-Bold !important;
    padding: 15px 20px !important;
}
.data_hack header .mwc-navbar li .dropdown-menu li {
    margin: 0 !important;
}
.data_hack header .mwc-navbar li .dropdown-menu li a:hover {
    color: #29f3e2 !important;
    background: transparent !important;
}
.data_hack header .mwc-navbar.mwc-fillbg1 .sub_logo img {
    height: 20px;
}
.data_hack header .mwc-navbar.mwc-fillbg1 .sub_logo span {
    font-size: 12px;
}
.data_hack .mwc-navbar.mwc-fillbg1 .navbar-brand img {
    height: 60px;
}
.video_sec {
    background: #000;
}
.video_sec .cont_sec {
    padding: 50px 25px 50px 0;
    width: 350px;
}
.video_sec .d-flex {
    align-items: inherit;
}
.video_sec .cont_sec > .blk:not(:last-child) {
    margin-bottom: 20px;
}
.video_sec .cont_sec > .blk p {
    font: 30px/40px Inter-Regular;
    color: #fff;
    font-style: italic;
    text-align: left;
}
.video_sec .cont_sec > .blk span {
    font: 35px/45px Inter-Bold;
    color: #29f3e2;
}
.video_sec .cont_sec > .blk {
    max-width: 320px;
}
.video_sec .cont_sec > .blk .txt {
    padding-left: 50px;
}
.video_sec .cont_sec > .blk .icon img {
    width: 90px;
    object-fit: contain;
}
.vid_sec {
    width: calc(100% - 350px);
}
.abt_sec {
    background: #d9d9d9;
    padding: 135px 0;
}
.abt_info.accordion {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.abt_info.accordion .accordion-item {
    width: 32%;
}
.abt_info.accordion .accordion-item h2 button::after {
    display: none;
}
.abt_sec .dropdown-content-bond {
    margin: 0 !important;
    font: 24px / 36px Halcom-Medium !important;
    color: #000;
}
.abt_sec select {
    font: 32px/36px 'Halcom-Medium' !important;
    color: #29f3e2 !important;
    margin-bottom: 25px;
    padding: 10px 20px;
    border: 2px solid #041d3f;
    border-radius: 15px;
    background: #041d3f;
}
.abt_sec select:focus {
    outline: none !important;
}
.tracks_chag .head {
    background: #000;
    padding: 50px 0;
}
.tracks_chag .head h2 {
    color: #29f3e2;
    font: 80px / 80px BodoniCondC !important;
    letter-spacing: 2px;
    margin-bottom: 20px !important;
}
.tracks_chag .head p {
    font: 20px/32px Halcom-Medium !important;
    color: #fff !important;
}
.jud_sec .d-flex .txt button.btn {
    background: #041d3f;
    color: #29f3e2 !important;
    font: 26px / 30px Halcom-Medium !important;
    padding: 30px 40px;
    text-transform: uppercase;
    border: 5px solid #041d3f !important;
    border-radius: 15px !important;
    margin: 25px auto 0;
}
.jud_sec .d-flex .txt button.btn:hover {
    background: transparent;
    color: #041d3f !important;
}
.sub_sec2 {
    padding: 50px 0;
    background: #000;
}
.atcb-saved .atcb-checkmark {
    display: none !important;
}
add-to-calendar-button {
    position: absolute !important;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0 !important;
    display: block;
    z-index: 10;
}
add-to-calendar-button .atcb-button {
    width: 100% !important;
    height: 100% !important;
}
.data_hack footer ul li a {
    text-decoration: none;
    color: #fff;
}
.qus {
    background: #00ade6;
}
.qus .tab-content .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.qus .tab-content .box > div {
    width: 20%;
    position: relative;
}
.qus .tab-content .box .img {
    width: 43%;
}
.qus .tab-content .box > div {
    width: 20%;
    position: relative;
}
.qus .tab-content .box > div p {
    text-align: left;
    color: #000;
    font-size: 16px !important;
    line-height: 24px !important;
    font-family: Halcom-Regular !important;
}
.qus .tab-content .box > div ul li {
    color: #000;
    font: 16px/24px Halcom-Bold !important;
}
.qus .tab-content .box > div h3 {
    font: 24px/24px Halcom-Bold  !important;
    color: #29f3e2;
    position: absolute;
}
.qus .tab-content .box > div > .top {
    margin-bottom: 80px;
}

.qus .tab-content .box > .lft_txt h3 {
    margin: 0 !important;
    right: -40%;
    top: 50%;
    z-index: 1;
}
.qus .tab-content .box > .lft_txt h3::before {
    content: "";
    width: 120px;
    height: 120px;
    background: url(../public/assets/img/circle.png) no-repeat center;
    background-size: contain;
    position: absolute;
    right: -113px;
    z-index: 1;
    top: -70px;
    animation: pulse-animation 1s infinite;
    animation-delay: 0.5s;
    border-radius: 100%;
}
.qus .tab-content .box > .lft_txt h3::after {
    content: "";
    width: 240px;
    height: 40px;
    background: url(../public/assets/img/line.png) no-repeat center;
    background-size: contain;
    position: absolute;
    z-index: 1;
    right: -65px;
    top: -28px;
}
.qus .tab-content .box > div > .top > h3 {
    margin: 0 !important;
    top: 130px;
    left: -200px;
}
.qus .tab-content .box > div > .top > h3::before {
    content: "";
    width: 110px;
    height: 110px;
    background: url(../public/assets/img/circle.png) no-repeat center;
    background-size: contain;
    position: absolute;
    z-index: 1;
    left: -140px;
    top: -25px;
    animation: pulse-animation 1s infinite;
    animation-delay: 1.5s;
    border-radius: 100%;

}
.qus .tab-content .box > div > .top > h3::after {
    content: "";
    width: 305px;
    height: 40px;
    background: url(../public/assets/img/line.png) no-repeat center;
    background-size: contain;
    position: absolute;
    z-index: 1;
    right: -65px;
    top: 15px;
}
.qus .tab-content .box > div .btm {
    position: relative;
}
.qus .tab-content .box > div .btm h3 {
    margin: 0 !important;
    left: -275px;
    top: 135px;
}
.qus .tab-content .box > div .btm h3::after {
    content: "";
    width: 400px;
    height: 40px;
    background: url(../public/assets/img/line.png) no-repeat center;
    background-size: contain;
    position: absolute;
    z-index: 1;
    right: -180px;
    top: 30px;
}
.qus .tab-content .box > div .btm h3::before {
    content: "";
    width: 155px;
    height: 155px;
    background: url(../public/assets/img/circle.png) no-repeat center;
    background-size: contain;
    position: absolute;
    z-index: 1;
    left: -190px;
    top: -25px;
    animation: pulse-animation 1s infinite;
    animation-delay: 2.5s;
    border-radius: 100%;
}
.data_hack header .mwc-navbar.mwc-fillbg1 .navbar-collapse {
    padding-top: 15px;
}
.qus .tab-content .box .img img {
    width: 100%;
    object-fit: contain;
}
.qus .tab-content .box .img h3 {
    display: none;
}
.qus {
    padding-top: 80px;
}

.abt_cnt .blks h4 {
    font: 32px/32px Halcom-Bold;
    color: #000;
    margin-bottom: 10px !important;
}
.abt_cnt .blks p {
    text-align: left;
    font: 20px/30px Inter-Regular;
    color: #000;
    margin: 0 !important;
}
.abt_cnt .blks:not(:last-child) {
    margin-bottom: 35px;
}
.data_hack .navbar-expand-lg .navbar-nav .dropdown-menu {
    width: 500px;
    padding: 20px;
}
.data_hack header .mwc-navbar li .dropdown-menu li .blks h5 {
    font: 18px/22px Halcom-Bold;
    color: #000;
}
.data_hack header .mwc-navbar li .dropdown-menu li .blks p {
    font: 16px/24px Halcom-Regular;
    color: #000;
    text-align: left;
    margin: 0;
}
.data_hack header .mwc-navbar li .dropdown-menu li:not(:last-child) {
    margin-bottom: 15px !important;
}
.data_hack.agenda_page header .mwc-navbar {
    background: rgb(20 20 20);
}
/*Data Hackathon New*/

div#new_speaker h4 {
    text-transform: uppercase;
}

/*RESPONSIVE*/
@media (max-width: 1860px) {
    div#archived .a_blk p bdi {display: inline;}
}

@media (max-width: 1680px) {
    .mwc-sponsors img {max-width: 100%!important;}
    .mwc-about, .mwc-agenda, .mwc-speakers, .mwc-sponsors, .mwc-pricing, .mwc-events {padding: 80px 100px;}
    .head_container {padding: 0 15px;}
}

@media (max-width: 1560px) {
    .spk_blk {width: 25%;}
    .mwc-banner .carousel-inner .carousel-item > img {min-height: 650px;}
    .sub_logo {left: 15px;bottom: -18px;}
}

@media (max-width: 1440px) {
    .mwc-banner .carousel-inner .carousel-item > img {min-height: 750px;}
    .innerheader .carousel-inner .carousel-item > img {min-height: 450px;}
    .mwc-banner .mwc-con {padding-top: 255px;}
    .mwc-banner .mwc-con h1 {font-size: 30px;}
    div#fodh h2 {font-size: 100px !important;line-height: 100px !important;}
    div#fodh p {font-size: 30px !important;line-height: 38px !important;}
    .navbar > .container {max-width: 100%;}
    .mwc-navbar li a, .mwc-navbar li button {padding: 20px 15px !important;}
    .v-body video {width: 100%;}
    .v-body .row {align-items: center;}
    .htd h2 {font-size: 64px !important;line-height: 70px !important;}
    .bdy .txt p {font-size: 22px !important;}
    .wht_build .bks:last-child {padding: 0;}
    .linked_btns .d-flex .btn {width: 24%;padding: 20px;font-size: 26px !important;}
}

@media (max-width: 1399px) {
    .tracks_chag .bdy .txt {height: 920px;}
    .tracks_chag .bdy .txt .blks:first-child::before {width: 65%;right: -30px;}
    .tracks_chag .bdy .txt .blks:first-child::after {width: 35px;right: -59px;top: 37px;}
}

@media (max-width: 1366px) {
    .brownbgsec .owl-next {right: -30px !important;}
    .brownbgsec .owl-prev {left: -30px;}
    .type:after {margin-top: 1.2em;}
    .ban_img img, .data_banner .item {min-height: 750px;height: 100%;}
    .data_banner .item h2 {text-align: center;line-height: 70px;}
    .faq .bdy .rft {width: 50%;}
    .qus .tab-content .box > div > .top > h3 {top: 225px;}
    .qus .tab-content .box > div .btm h3::after {width: 355px;}
    .qus .tab-content .box > div .btm h3::before {width: 120px;height: 120px;left: -130px;top: -10px;}
    .data_hack header .container {max-width: 100% !important; width: 100% !important;}
    .mwc-navbar li a, .mwc-navbar li button {padding: 20px 17px !important;}
    .mwc-navbar li a, .mwc-navbar li button {font-size: 14px !important;}
    .sub_logo span {font-size: 10px;}
    .sub_logo img {max-width: 150px !important;height: 30px !important;}
    .sub_logo {bottom: -15px;}
    #c-banner {padding-top: 40px;}
    .data_hack footer {height: auto;}
    .reg_sec h2 {font-size: 110px !important;line-height: 110px !important;}
    .data_banner {max-height: 675px;min-height: 675px;}
    .data_banner .ban_cnt h2 {font-size: 66px !important;line-height: 75px !important;}
    .qus {padding: 50px 0 100px;}
}

@media (max-width: 1200px) {
    .mwc-sponsors.mwc-speakers > div {margin: 0!important;}
    .mwc-sponsors.mwc-speakers > div .col-md-2 {width: 25%;}
    .mwc-about-conr {padding-right: 20px;}
    .pillar_head h2 {margin-bottom: 100px!important;}
    div#Speakers > h2 {font-size: 35px!important;line-height: 35px;padding: 30px 100px;}
    .spk_blk {width: 33.33%;}
    div#archived .a_blk {padding: 0 20px;}
    div#new_Sponsors.mwc-sponsors img {max-width: 300px!important;}
    .mwc-navbar li a, .mwc-navbar li button {padding: 20px!important;}
    .eventspelist .col-md-3 {width: 33.3%;}
    .mwc-about img {margin-bottom: 0;}
    .mwc-banner .mwc-con {padding-top: 220px;}
    .mwc-banner {height: 770px;}
    .mwc-banner .mwc-con h1 {font-size: 28px;}
    .mwc-banner .mwc-con .yeartxt {padding-top: 175px;}
    .mwc-navbar li a, .mwc-navbar li button {font-size: 17px;}
    div#about ul li .txt h4 {font-size: 26px;line-height: 32px;margin: 0;}
    .commmunitywrap h2 {font-size: 50px !important;line-height: 50px;}
    .commmunitywrap p {font-size: 20px !important;line-height: 30px;max-width: 650px;}
    .navbar > .container {max-width: 100% !important;width: 100% !important;}
    .modal.display-block {min-height: 720px;}
    .v-body video {height: auto;}
    .v-body p {font-size: 20px !important;line-height: 30px;}
    .mwc-navbar li a, .mwc-navbar li button {padding: 20px 8px !important;}
    .abt_sec .blk > div img {width: 100%;}
    .data_banner .ban_cnt h2 {font-size: 60px !important;line-height: 75px !important;max-width: 700px;}
    .ban_img img {max-height: 650px;}
    .ban_img img, .data_banner, .data_banner .item {min-height: 650px;}
    .htd h2 {font-size: 55px !important;line-height: 55px !important;}
    .dt_future .btns .btn {width: 48%;font-size: 22px !important;padding: 20px 15px;}
    .abt_sec h2, .tracks_chag h2, .resource h2, .scdle h2, .price_cate h2 {font-size: 65px !important;line-height: 65px !important;margin-bottom: 40px !important;}
    .abt_sec .blk > div {width: 47%;}
    .abt_sec .blk > div h4 {font-size: 34px !important;}
    .abt_sec .blk > div p {font-size: 20px !important;line-height: 30px !important;}
    .tracks_chag .bdy .txt .blks:first-child::before {width: 55%;right: 25px;top: 20px;}
    .tracks_chag .bdy .txt .blks:first-child::after {width: 25px;right: 5px;top: 28px;}
    .tracks_chag .bdy .txt .blks {max-width: 475px;}
    .bdy .txt p, .tracks_chag .bdy .txt .blks ul li {font-size: 20px !important;}
    .tracks_chag .bdy .txt .blks:nth-child(2)::before {width: 100%;right: -210px;top: 15px;}
    .tracks_chag .bdy .txt .blks:nth-child(2)::after {width: 20px;right: -225px;top: 21px;}
    .sft_box .d-flex .box {padding: 35px 35px 15px;}
    .sft_box .d-flex .box h4 {font-size: 30px !important;line-height: 36px !important;}
    .sft_box .d-flex .box .img img {max-width: 100px;}
    .in_prices h2, .reg_sec h2 {font-size: 75px !important;line-height: 75px !important;margin-bottom: 40px !important;}
    .wht_build .bks .txt ul li {font-size: 20px !important;}
    .wht_build .bks .txt h3 {    font-size: 30px !important;margin-bottom: 15px !important;}
    .wht_build h5 {font-size: 22px !important;}
    .sub_sec .head h2 {font-size: 60px !important;line-height: 60px !important;}
    .jud_sec .d-flex .img {width: 55%;}
    .jud_sec .d-flex .txt p {text-align: left;}
    .reg_sec button {font-size: 22px !important;padding: 20px 35px;}
    .reg_sec .blks > .txt p, .spon_sec p {font-size: 20px !important;}
    .reg_sec .blks > .txt p:last-child {margin: 0 !important;}
    .faq_sec > div > h2, .data_hack footer h2 {font-size: 35px !important;line-height: 35px !important;}
    .faq_sec .faq-accor .accordion-item h2 button {font-size: 20px !important;}
    .spon_sec h2 {font-size: 55px !important;line-height: 55px !important}
    .spon_sec h5 {font-size: 24px !important;}
    .data_hack footer ul li {font-size: 22px !important;}
    .data_hack footer .social_icons ul li a {font-size: 50px;}
    .social_icons ul {margin-bottom: 0;}
    .data_banner {max-height: 635px;min-height: 635px;}
    .video_sec .cont_sec > .blk .icon img {width: 65px;}
    .video_sec .cont_sec > .blk span {font-size: 30px;line-height: 40px;}
    .video_sec .cont_sec > .blk p {font-size: 26px;line-height: 36px;}
    .dt_future .bdy .txt p {font-size: 22px !important;}
    .sub_sec2 h2 {font-size: 70px !important;line-height: 70px !important;}
    .sub_sec2 h2 span {font-size: 45px !important;}
    .data_hack .sub_logo {width: max-content;position: absolute;bottom: 2px;}
    .data_hack header .mwc-navbar.mwc-fillbg1 .sub_logo img {height: 20px !important;}
}

@media (max-width: 1140px) {
    .mwc-navbar {border-width: 5px;}
    .mwc-banner .carousel-inner .carousel-item > img {min-height: 650px;}
    div#new_Sponsors.mwc-sponsors img {max-width: 260px!important;}
    div#contact form .frm label {font-size: 20px!important;}
    .mwc-navbar li a, .mwc-navbar li button {font-size: 15px;}
    .pillar_boday .blk {width: 33.3%;}
    .innerheader .carousel-inner .carousel-item > img {min-height: 450px;}
    .mwc-footer {padding: 50px 85px;}
    .mwc-banner .mwc-con img {height: 280px;}
    .mwc-banner .mwc-con h1 {font-size: 25px;}
    .mwc-banner .mwc-con h1 span {font-size: 18px;}
    .mwc-footer {padding: 35px 0px !important;}
    .socialdevlop {flex-direction: column;}
    .copyrights {padding: 10px 0;}
    .agenda .tpt .rft {width: 35%;}
    .agenda .tpt .rft img {width: 100%;object-fit: contain;}
    .agenda h2, .winner h2, .qus .nav-pills a, .faq h2, .sponsors .top h2, .qus .nav-pills a {font-size: 65px !important;line-height: 65px !important;}
    .qus .tab-content .box > div > .top > h3 {top: 275px;}
    .qus .tab-content .box > div .btm h3 {left: -200px;}
    .qus .tab-content .box > div .btm h3::after { width: 305px;right: -115px;}
    .qus .tab-content .box > div .btm h3::before {left: -150px;}
    .data_banner .ban_cnt h2 {font-size: 50px !important;line-height: 65px !important;max-width: 600px;}
    .data_banner .ban_cnt p {font-size: 22px !important;}
    .ban_img img, .data_banner, .data_banner .item {min-height: 600px;}
    .ban_img img {max-height: 600px;}
    .htd h2 {font-size: 50px !important;line-height: 55px !important;}
    .bdy .txt p {margin-bottom: 40px;}
    .bdy .txt ul li {font-size: 22px !important;}
    .abt_sec h2, .tracks_chag h2, .resource h2, .scdle h2, .price_cate h2 {font-size: 55px !important;line-height: 55px !important;margin-bottom: 30px !important;}
    .tracks_chag .bdy .txt .blks:first-child {left: 10%;}
    .tracks_chag .bdy .txt .blks:nth-child(2) {top: 40%;}
    .tracks_chag .bdy .txt .blks:last-child {bottom: 15%;right: 25%;}
    .tracks_chag .bdy .txt .blks:nth-child(2)::before {width: 75%;right: -135px;top: 10px;}
    .tracks_chag .bdy .txt .blks:nth-child(2)::after {right: -150px;top: 16px;}
    .linked_btns .d-flex .btn {font-size: 20px !important;padding: 20px 40px;}
    .accordion-body p, .accordion-body {font-size: 18px;}
    .data_banner {max-height: 570px;min-height: 570px;}
}

@media (max-width: 1024px) {
    .mwc-banner .carousel-inner .carousel-item > img {min-height: 550px;}
    .mwc-sponsors.mwc-speakers > div {padding-top: 0;}
    .mwc-sponsors.mwc-speakers > div .col-md-2 {width: 33.33%;}
    .mwc-about .row .col-md-5 {width: 100%;}
    .mwc-about .row .col-md-7 {width: 80%;}
    div#Speakers > h2 {font-size: 30px!important;line-height: 30px;padding: 25px 70px;}
    .spk_blk .img img {width: 100%;min-height: 260px;max-height: 260px;}
    div#awards h2 {margin-bottom: 50px!important;}
    div#awards .boxed {margin-bottom: 0;border-width: 5px;}    
    .pillar_boday .blk:not(:last-child) {margin-right: 50px;}
    .pillar_boday .blk {width: 25%;}
    .innerheader .carousel-inner .carousel-item > img {min-height: 450px;}
    .mwc-c_banner {padding: 30px 80px;}  
    .mwc-banner {height: auto;}
    .mwc-banner .mwc-con .yeartxt img {height: 250px;}
    .btnbottomalign {justify-content: center;}
    .mwc-banner .mwc-con .yeartxt {padding-top: 0;}
    .mwc-banner .mwc-con {padding-top: 85px;height: 100%;display: flex;align-items: center;justify-content: center;}
    .mwc-banner .mwc-con .row > div {width: 50%;}
    div#fodh h2 {font-size: 80px !important;line-height: 80px !important;}
    div#fodh p {font-size: 26px !important;line-height: 35px !important;}
    .revengwrap .pfdf40 {font-size: 20px !important;}
    .ban_img img, .data_banner, .data_banner .item {min-height: 630px;}
    .data_banner .item h2 {font-size: 60px !important;}
    .agenda, .qus, .faq, .sponsors {padding: 80px 0;}
    .winner p {margin-bottom: 50px;}
    .winner .btns {margin-top: 60px;}
    .faq .bdy .rft {width: 40%;}
    .sponsors .top {margin-bottom: 100px;}
    video#video {height: 705px;}
    .data_banner .ban_cnt h2 {font-size: 50px !important;}
    .qus .tab-content .box {flex-direction: column;}
    .qus .tab-content .box .img {width: 55%;orphans: 1;position: relative;}
    .qus .tab-content .box > .lft_txt {width: 100%;order: 2;}
    .qus .tab-content .box > .rft_txt {width: 100%;order: 3;}
    .qus .tab-content .box > .lft_txt h3 {position: relative;left: unset;right: unset !important;margin-bottom: 20px !important;}
    .qus .tab-content .box > div .btm h3, .qus .tab-content .box > div > .top > h3 {left: unset;right: unset !important;position: relative;top: unset;margin-bottom: 20px !important;}
    .qus .tab-content .box > div > .top {margin: 35px 0;}
    .qus .tab-content .box > .lft_txt h3::before, .qus .tab-content .box > div .btm h3::before, .qus .tab-content .box > div > .top > h3::before, .qus .tab-content .box > .lft_txt h3::after, .qus .tab-content .box > div .btm h3::after, .qus .tab-content .box > div > .top > h3::after {display: none;}
    .qus .tab-content .box .img h3 {display: block;position: absolute;margin: 0 !important;z-index: 1;}
    .qus .tab-content .box .img h3::before {content: "";width: 70px;height: 70px; background: url(../public/assets/img/circle.png) no-repeat center;background-size: contain;position: absolute;z-index: 1;animation: pulse-animation 1s infinite;animation-delay: 1.5s;border-radius: 100%;}
    .qus .tab-content .box .img h3::after {content: "";width: 180px;height: 40px;background: url(../public/assets/img/line.png) no-repeat center;background-size: contain;position: absolute;z-index: 1;}
    .qus .tab-content .box > .img > h3:first-child {top: 225px;left: -120px;}
    .qus .tab-content .box > .img > h3:first-child::before {right: -100px;top: 0;}
    .qus .tab-content .box .img h3:first-child::after {top: 17px;right: -75px;}
    .qus .tab-content .box .img h3:nth-child(2) {right: -70px;top: 145px;}
    .qus .tab-content .box .img h3:nth-child(2)::before {left: -110px;top: -8px;}
    .qus .tab-content .box .img h3:nth-child(2)::after {top: 10px;left: -90px;width: 250px;}
    .qus .tab-content .box .img h3:nth-child(3) {bottom: 90px;right: 35px;}
    .qus .tab-content .box .img h3:nth-child(3)::before {left: -160px;}
    .qus .tab-content .box .img h3:nth-child(3)::after {left: -135px;width: 250px;top: 10px;}
    .mwc-banner .mwc-con .yeartxt svg {width: 400.13px;height: 265px;}
    .hoverme .pop {width: 80%;}
    .data_hack header .mwc-navbar li a, .data_hack header .mwc-navbar li button {font-size: 16px !important;}
    .mwc-navbar li a, .mwc-navbar li button {font-size: 14px;padding: 20px 10px !important;}
    .v-foot > .row > div h4 {font-size: 36px !important;}
    .dt_future .btns .btn {font-size: 20px !important;}
    .dt_future .btns {margin-bottom: 35px !important;}
    .sub_sec .head h2 {font-size: 50px !important;line-height: 50px !important;}
    .data_banner {max-height: 550px;min-height: 550px;}
    .data_hack .sub_logo {padding-left: 35px;}
    .resource .txt, .scdle .txt {padding-left: 60px;}
    .sub_sec2 h2 {font-size: 55px !important;line-height: 55px !important;}
    .sub_sec2 h2 span {font-size: 35px !important;}
    .agenda {padding: 150px 0 50px;}
}

@media (max-width: 991px) {
    .mwc-navbar {border-width: 1px;}
    .mwc-banner .carousel-inner .carousel-item > img {min-height: 500px;}
    .mwc-c_banner {padding: 20px 25px;}
    .mwc-about-conr h2 {font-size: 35px!important;line-height: 35px;}
    .mwc-about, .mwc-agenda, .mwc-speakers, .mwc-sponsors, .mwc-pricing, .mwc-events {padding: 60px 50px;}
    .pillar_head h2 {margin-bottom: 60px!important;}
    .pillar_boday {flex-wrap: wrap;}
    .pillar_boday .blk {width: 30%;margin-bottom: 30px;}
    .pillar_boday .blk .icon img {margin-bottom: 0;}
    div#new_Sponsors.mwc-sponsors img {max-width: 215px!important;}
    div#contact form .frm label {margin-bottom: 10px;}
    div#contact form .frm input {width: 100%;}
    .mwc-footer {padding: 35px 30px 50px;}
    div#contact h2 {font-size: 35px !important;line-height: 35px;margin-bottom: 50px !important;}
    .pillar_boday .blk:not(:last-child) {margin: 0;}
    .mwc-speakers-list {flex-wrap: nowrap;overflow-x: auto;justify-content: flex-start; margin-bottom: 15px;}
    .spk_blk{margin-bottom: 15px;padding: 0 15px;}
    #pills-tabContent + .mwc-btn-sec {margin-bottom: 15px;}
    .innerheader, .innerheader .carousel-inner .carousel-item > img  {min-height: 250px; height: 250px; }
    .innerheader .mwc-con{ padding: 50px 15px 15px;}
    .innerheader h1{ font-size: 20px; text-align: center;}
    .innerheader p{text-align: center; font-size: 12px;}
    ul.navbar-nav li {opacity: 1;animation: none;}
    .mwc-navbar .navbar-brand {text-align: left;}
    div#About .txt .mwc-about-conr .trt {flex-direction: column;}
    div#About .txt .mwc-about-conr .trt ul {width: 100%;max-width: 100%;margin-bottom: 25px;}
    .pillar_boday .blk .txt {padding: 15px 10px;}
    .pillar_boday .blk .txt p {font-size: 17px;line-height: 24px;}
    .row.col-md-12.mwc-btn-sec._dsk_view {display: none;}
    .row.col-md-12.mwc-btn-sec._mbl_view {display: block;margin: 0;}
    .mwc-banner .mwc-con h3 {font-size: 20px !important;}
    .mwc-banner .mwc-con h2 {font-size: 22px !important;margin-bottom: 0 !important;}
    .mwc-banner .mwc-con .yeartxt img {height: 200px;}
    .socialdevlop {flex-direction: column;}
    .mwc-footer {padding: 35px 0px !important;}
    .copyrights {padding: 10px 0;}
    .mwc-banner {min-height: 560px;}
    .mwc-navbar li a, .mwc-navbar li button {display: block;width: 100%;text-align: center;}
    .winner h2, .winner h4 {text-align: center;}
    .sponsors .bdy {flex-direction: column;}
    .sponsors .bdy .slider {width: 100%;margin-bottom: 50px;}
    .sponsors .fyt {padding-top: 80px;}
    .faq .bdy .lft .nav-pills .nav-item a::after {right: -1px;}
    .bdy._desk {display: none;}
    .bdy._mob {display: block;}
    .bdy._mob .btns {margin-top: 50px;}
    .cnt {padding: 80px 0;}
    video#video {height: 760px;}
    .mwc-banner .mwc-con .yeartxt svg {width: 345px;height: 225px;}
    div#about h2 {margin-bottom: 60px !important;}
    div#about ul li {width: 45%;margin-bottom: 35px;}
    div#about ul li .txt h4 {font-size: 22px;line-height: 30px;}
    div#new_speaker .owl-next {right: -28px !important;}
    div#new_speaker .owl-prev {left: -28px;}
    .hoverme .pop {width: 60%;}
    .hoverme .pop p {font-size: 18px !important;}
    .new-accor .accordion-header .accordion-button {font-size: 26px;height: 70px;}
    .accordion-body p, .accordion-body {font-size: 17px;}
    .modal.display-block {top: 64px;}
    .sub_logo {bottom: unset;position: relative;}
    .mwc-navbar {height: 100px;}
    .mwc-navbar .sub_logo img {max-width: 180px !important; }
    .navbar-collapse {top: 75px;}
    .linked_btns .d-flex .btn {padding: 15px;font-size: 16px !important;}
    .data_banner .ban_cnt h2 {font-size: 45px !important;line-height: 55px !important;}
    .ban_img img, .data_banner, .data_banner .item {min-height: 550px;}
    .ban_img img {max-height: 550px;}
    .data_banner .ban_cnt p {font-size: 18px !important;}
    .htd h2 {font-size: 40px !important;line-height: 46px !important;margin-bottom: 50px !important;}
    .bdy .img img {padding-left: 30px;}
    .dt_future .btns {width: 70%;}
    .abt_sec .blk:not(:last-child) {margin-bottom: 25px !important;}
    .abt_sec .blk > div h4 {font-size: 30px !important;}
    .abt_sec .blk > div p {font-size: 18px !important;}
    .text_sec p {font-size: 20px !important;}
    .abt_sec h2, .tracks_chag h2, .resource h2, .scdle h2, .price_cate h2 {font-size: 45px !important;line-height: 45px !important;margin-bottom: 30px !important;}
    .tracks_chag p {font-size: 20px !important;}
    .tracks_chag .bdy .txt .blks:first-child {left: 0%;}
    .tracks_chag .bdy .img {width: 70%;}
    .tracks_chag .bdy .txt .blks:first-child::before {width: 45%;right: 70px;top: 10px;}
    .tracks_chag .bdy .txt .blks:first-child::after {width: 25px;right: 50px;top: 18px;}
    .bdy .txt h4 {font-size: 26px !important;}
    .bdy .txt p, .tracks_chag .bdy .txt .blks ul li {font-size: 18px !important;line-height: 26px !important;}
    .tracks_chag .bdy .txt .blks {max-width: 400px;}
    .tracks_chag .bdy .txt .blks:last-child {right: 33%;}
    .tracks_chag .bdy .txt .blks:nth-child(2) {top: 43%;}
    .tracks_chag .bdy .txt .blks:nth-child(2)::before {width: 60%;right: -40px;top: 10px;}
    .tracks_chag .bdy .txt .blks:nth-child(2)::after {right: -55px;}
    .sft_box, .resource, .jud_sec, .price_cate, .spon_sec {padding: 35px 0;}
    .sft_box .d-flex .box h4 {font-size: 22px !important;line-height: 28px !important;}
    .sft_box .d-flex .box p {font-size: 18px !important;}
    .wht_build .bks .txt h3 {font-size: 25px !important;}
    .sub_sec .head h2 {font-size: 40px !important;line-height: 40px !important;}
    .sub_sec .head p {font-size: 22px !important;}
    .jud_sec .d-flex .txt h3 {font-size: 36px !important;}
    .data_hack .navbar-nav li:not(:first-child) {margin-left: 0;margin-top: 15px;}
    .data_hack .navbar-nav {padding: 25px;}
    .reg_sec p {font-size: 22px !important;margin-bottom: 50px !important;}
    .tracks_chag .bdy .txt p {font-size: 20px !important;line-height: 28px !important;}
    .dt_future .bdy .txt p {font-size: 20px !important;line-height: 32px !important;}
    .data_hack .navbar-toggler-icon {filter: unset;}
    .data_hack .navbar-toggler {background: #29f3e2 !important;color: #000 !important;}
    .data_hack header .mwc-navbar {background: rgb(20 20 20);}
    .data_hack .logo {display: flex;align-items: center;justify-content: flex-start;}
    .data_hack .sub_logo {padding-left: 0;}
    .data_hack header .mwc-navbar .container {align-items: center;}
    .data_banner {margin-top: 115px;max-height: 425px;min-height: 425px;}
    .ban_cnt {align-items: center;}
    .data_hack .navbar-collapse {top: 90px;}
    .abt_sec {padding: 115px 0;}
    .jud_sec .d-flex .txt button.btn {font-size: 22px !important;padding: 20px 30px !important;}
    .sub_sec2 h2 {font-size: 50px !important;line-height: 50px !important;}
    .tracks_chag .head h2 {font-size: 60px !important;line-height: 60px !important;}
    .in_prices h2 {font-size: 65px !important;line-height: 65px !important;margin-bottom: 30px !important;}
    .data_hack .navbar-expand-lg .navbar-nav .dropdown-menu {width: 100%;}
}

@media (max-width: 767px) {
    .pillar_boday .blk {width: 100%;max-width: 100%;}
    .pillar_boday .blk button {width: 100%;}
    div#new_Sponsors.mwc-sponsors img {max-width: 160px!important;margin: auto;}
    .spk_blk {width: 60%;min-width: 300px;}
    div#archived .row > div{ margin-bottom: 30px;}
    .pillar_boday .blk:not(:last-child) {margin-right: 0;}
    .pillar_boday {flex-direction: column;align-items: center;}
    div#archived .a_blk{ padding: 0px;}
    div#archived .a_blk p{ font-size: 12px;}
    div#archived .a_blk span{ padding: 0px;}
    .mwc-banner .mwc-con .powby > span { font-size: 20px;  line-height: 20px; margin: 5px 0;}
    .eventspelist {padding: 0px !important;}
    .eventspelist .row{ flex-wrap: wrap;}
    .eventspelist .col-md-3{width: 50%;}
    .pillar_modal .modal-body {padding: 0 15px 20px;}
    .pillar_modal .modal-body p{font-size: 12px; line-height: 20px; text-shadow: none;  text-align: center;}
    .pillar_modal .modal-body h4{font-size: 20px !important; margin-bottom: 0px;}
    .eventspelist .mwc-propic{ width: 150px; height: 150px;}
    .c_banner .c_banner_txt p, div#Speakers > h2 {padding: 0;}
    div#awards h2 {font-size: 38px!important;}
    div#awards .boxed {padding: 30px;}
    div#archived .a_blk p span {margin: 0;}
    div#contact form .frm {flex-direction: column;margin-bottom: 25px;}
    div#contact form .frm ._frm {width: 100%;}
    div#contact form .frm ._frm:not(:last-child) {margin-bottom: 25px;}
    div#contact form .frm textarea {width: 100%;}
    div#contact form .frm input, div#contact form .frm textarea {font-size: 16px;padding: 18px 20px;}
    div#contact form .frm:last-child {margin-bottom: 0;}
    .mwc-banner .mwc-con .yeartxt img {height: 195px;}
    .mwc-banner .mwc-con .yeartxt img {height: 175px;}
    .mwc-banner .mwc-con h1 span {font-size: 12px;line-height: 24px;}
    .mwc-social-links, .copyrights {margin: 0;}
    .builtwallet .developlink .devlotxt {text-align: left;}
    .new-accor .accordion-header .accordion-button {justify-content: flex-start;}
    .new-accor.accordion {width: 100%;}
    .brownbgsec .owl-next {right: 0px !important;}
    .brownbgsec .owl-prev {left: 0px;}
    .mwc-banner .mwc-con h1 {font-size: 24px;}
    div#fodh {padding: 75px 0;}
    div#fodh h2 {font-size: 60px !important;line-height: 60px !important;margin-bottom: 35px !important;}
    div#fodh p {font-size: 22px !important;line-height: 32px !important;margin-bottom: 40px;}
    .ban_img img, .data_banner, .data_banner .item {min-height: 550px;}
    .data_banner .item h2 {font-size: 45px !important;line-height: 45px !important;margin-bottom: 30px !important;}
    .data_banner .item p {font-size: 22px !important;}
    .agenda h2, .winner h2, .qus .nav-pills a, .faq h2, .sponsors .top h2 {font-size: 55px !important;line-height: 55px !important;}
    .agenda .tpt {flex-direction: column;}
    .agenda .tpt .rft {width: 65%;}
    .agenda ul {margin-top: 50px;}
    .agenda .tpt .lft {width: 100%;}
    .ft_btns .btns {flex-direction: column;}
    .ft_btns .btns .btn:not(:last-child) {margin-bottom: 20px;}
    .ft_btns {padding: 35px 0;}
    .faq .bdy .accordion-item > h2.accordion-header::after {right: -1px;}
    .cnt {padding: 75px 0;}
    .data_banner .ban_cnt h2, .data_banner .ban_cnt h2 a {font-size: 45px !important;margin-bottom: 85px !important;line-height: 45px !important;-webkit-text-stroke: 2px white;}
    .qus .tab-content .box > .img > h3:first-child {top: 175px;}
    .qus .tab-content .box .img h3:nth-child(2) {top: 115px;}
    .qus .tab-content .box .img h3:nth-child(3) {bottom: 60px;right: 0;}
    .qus .nav-pills a {font-size: 35px !important;line-height: 35px !important;}
    video#video {height: 800px;}
    .tiwrap img {width: 100px;}
    .faq .bdy .accordion-item > h2.accordion-header::after {top: 1px;}
    .faq .bdy .accordion-item > h2.accordion-header button {font-size: 20px !important;}
    .mwc-banner .mwc-con .yeartxt svg {width: 200px; height: 132px;}
    div#new_speaker .owl-prev {left: -5px;}
    div#new_speaker .owl-next {right: -5px !important;}
    div#about ul li .txt h4 {font-size: 18px;line-height: 24px;}
    div#about ul li .icns {width: 55px;}
    div#about {padding: 70px 0;}
    .commmunitywrap h2 {font-size: 36px !important;line-height: 36px;}
    .commmunitywrap p:not(:last-child) {margin-bottom: 25px;}
    .commmunitywrap p {font-size: 17px !important;}
    .commmunitywrap p span {font-size: 24px;line-height: 24px;}
    .commmunitywrap p {margin-bottom: 25px;}
    .hoverme .pop::before {display: none;}
    .hoverme .pop {width: 100%;left: 0;position: relative;height: 0;top: 10px;padding: 0;}
    .hoverme p span:hover + .pop {height: auto;padding: 15px;}
    .modal.display-block {min-height: 560px;}
    .v-body p {padding-left: 0;padding-top: 20px;}
    .data_banner .ban_cnt h2 {line-height: 60px !important;margin-bottom: 50px !important;}
    .ban_img img, .data_banner, .data_banner .item {min-height: 485px;}
    .ban_img img {max-height: 485px;}
    .bdy .img {float: none;width: 75%;margin: 0 auto 35px;}
    .bdy .img img {padding-left: 0;}
    .dt_future .btns {width: 100%;}
    .abt_sec h2, .tracks_chag h2, .resource h2, .scdle h2, .price_cate h2 {text-align: center;}
    .abt_sec .blk {flex-direction: column-reverse;}
    .abt_sec .blk > div {width: 100%;}
    .abt_sec .blk:not(:last-child) {margin-bottom: 35px !important;}
    .abt_sec .blk:nth-child(3), .sft_box .d-flex {flex-direction: column;}
    .abt_sec .blk > .img {margin-bottom: 25px;}
    .sft_box .d-flex .box {width: 100%;}
    .sft_box .d-flex .box:not(:last-child) {margin-bottom: 25px;}
    .agenda_sec .img, .jud_sec .d-flex .img, .reg_sec .blks > .img {float: none;width: 75%;margin: 0 auto 50px;}
    .agenda_sec .txt h3 {text-align: center;}
    .resource h4 {font-size: 30px !important;line-height: 30px !important;margin-bottom: 25px !important;}
    .in_prices h2, .reg_sec h2 {font-size: 60px !important;line-height: 60px !important;margin-bottom: 30px !important;}
    .wht_build .bks {padding: 25px 0;flex-direction: column;}
    .wht_build .bks > div {width: 75%;margin: 0 auto 35px;}
    .wht_build .bks .txt {padding: 0;width: 100%;margin: 0;}
    .wht_build .bks:last-child > .txt {padding: 0;margin: 0;}
    .wht_build .bks:last-child {flex-direction: column-reverse;padding-bottom: 0;}
    .sub_sec .bdys .boxs .box {padding: 30px;}
    .jud_sec .d-flex, .reg_sec .blks {flex-direction: column-reverse;}
    .jud_sec .d-flex .txt, .reg_sec .blks > .txt {width: 100%;}
    .reg_sec p {font-size: 24px !important;margin-bottom: 50px !important;}
    .linked_btns .d-flex {flex-wrap: wrap;}
    .linked_btns .d-flex .btn {width: 48%;margin-bottom: 20px;}
    .linked_btns {padding-bottom: 30px;}
    .flx {flex-direction: column;}
    .data_banner .ban_cnt p {font-size: 18px !important;text-align: center;margin-top: 15px !important;line-height: 26px !important;}
    .data_banner .ban_cnt h2 {text-align: center;}
    .tracks_chag .bdy .txt {height: 780px;}
    .agenda_sec .txt .blks {padding-left: 0;}
    .dt_future .bdy .txt > p:first-child {margin-bottom: 40px;}
    .data_banner .ban_cnt h2 {-webkit-text-stroke: unset;}
    .dt_future .bdy .txt ul li {font-size: 24px !important;}
    .wht_build h3 {font-size: 55px !important;line-height: 55px !important;}
    .wht_build h4 {font-size: 30px !important;line-height: 30px !important;}
    .wht_build .bks:last-child > .txt > ul:last-child {margin: 0;}
    .wht_build {padding-bottom: 0;}
    .reg_sec p {font-size: 20px !important;margin-bottom: 35px !important;}
    .data_hack .mwc-navbar .navbar-brand img {height: 70px;}
    .data_banner {margin-top: 100px;}
    .flx > img {display: none;}
    .flx .txt {width: 100%;text-align: center;}
    .data_banner .ban_cnt h2 {line-height: 50px !important;max-width: 100%;margin-bottom: 25px !important;}
    .flx button {margin: auto;}
    .data_banner {margin-top: 100px;max-height: 370px;min-height: 370px;}
    .video_sec .d-flex {flex-direction: column;}
    .video_sec .cont_sec {padding: 25px;width: 375px;margin: auto;}
    .vid_sec {width: 100%;}
    .abt_sec {padding: 60px 0;}
    .data_agenda {padding: 50px 0;}
    .data_agenda {margin: 0;}
    .abt_cnt .blks h4 {font-size: 26px;line-height: 26px;}
    .abt_cnt .blks p {font-size: 18px;line-height: 26px;}
    .abt_cnt .blks:not(:last-child) {margin-bottom: 25px;}
    .agenda h2 {font-size: 46px !important;line-height: 46px !important;margin-bottom: 30px !important;}
    .agenda ul li p {text-align: left;margin: 0;line-height: 28px !important;}
    .agenda ul li h5 {font-size: 20px !important;line-height: 26px !important;}
}

@media (max-width: 660px) {
    .data_banner {max-height: 320px;min-height: 320px;}
}

@media (max-width: 575px) {
    .mwc-banner .mwc-con button{ min-width: 200px; letter-spacing: 5px;}
    div#Speakers > h2 {font-size: 24px!important;line-height: 30px;padding: 20px 50px;}
    .mwc-banner .mwc-con h3 span {display: inline;}
    .mwc-about, .mwc-agenda, .mwc-speakers, .mwc-sponsors, .mwc-pricing, .mwc-events {padding: 60px 25px;}
    .mwc-sponsors.mwc-speakers > div .col-md-2 {width: 50%;}
    .c_banner .c_banner_txt p {font-size: 13px;line-height: 26px;width: 100%;letter-spacing: 3px;}
    .mwc-about .row .col-md-7 {width: 85%;}
    .mwc-about-conr h2, .pillar_head h2 {font-size: 30px!important;line-height: 30px;}
    .spk_blk{width: 75%;}
    div#awards h2 {margin-bottom: 30px!important;font-size: 30px!important;line-height: 30px;}
    div#awards .boxed {padding: 40px 25px;}
    div#new_Sponsors h2 {font-size: 30px!important;line-height: 30px;margin-bottom: 20px !important;}
    div#new_Sponsors > div {margin: 0!important;}
    div#new_Sponsors.mwc-sponsors img {max-width: 100%!important;}
    .mwc-social-links {margin: 0;}
    .eventspelist .col-md-3{width: 100%;}
    .eventspelist .mwc-propic {width: 100px;height: 100px;}
    .mwc-events .pills-gv .nav-link, .mwc-events .pills-gv .show > .nav-link{ flex-direction: column;}
    .pills-gv .nav-link img{ margin-bottom: 10px; margin-right: 0px;}
    .innerheader .carousel-inner .carousel-item > img{ margin-left: -700px;}
    .mwc-banner .mwc-con h3 {font-size: 15px !important;}
    .mwc-banner .mwc-con h2 {font-size: 18px!important;}
    .d-icon {width: 35px;height: 40px;}
    div#About .txt .mwc-about-conr h2 {font-size: 35px !important;line-height: 45px;text-shadow: rgba(255, 255, 255, 0.5) 1.65164px 1.65164px 0px, rgba(255, 255, 255, 0.3) 2.30328px 2.30328px 0px;}
    div#About .txt .mwc-about-conr .trt h3 {font-size: 35px !important;line-height: 45px;}
    div#awards .boxed .blk {flex-direction: column;padding: 10px;}
    div#archived h2 {font-size: 35px !important;line-height: 45px;margin-bottom: 50px!important;}
    .mwc-footer {padding: 35px 30px 35px;}
    div#awards .boxed .blk {border-radius: 50px;}
    .btnbottomalign {justify-content: center; max-width: 275px; margin: 0px auto; align-items: center;}
    .btnbottomalign h1{ text-align: center !important;}
    .mwc-banner .mwc-con .row {flex-direction: column-reverse;}
    .mwc-banner .mwc-con .yeartxt {padding-top: 0; flex-direction: row; align-items: center; justify-content: center;}
    .mwc-banner .mwc-con h1 {top: 0;padding: 20px 0 15px;}
    .mwc-banner .mwc-con .row .col-5, .mwc-banner .mwc-con .row .col-7 {width: 100%; max-width: 100%;margin: 0 auto;}
    .mwc-banner .mwc-con .yeartxt img { height: 160px;}
    .mwc-banner .mwc-con {padding-top: 75px;}
    .mwc-banner .mwc-con h1 {font-size: 18px;}
    .speaker_slider._desktop {display: none;}
    .speaker_lineup._mobile {display: block;}
    .speaker_lineup._mobile .list {width: 100%;max-width: 250px;margin: auto;text-align: center;}
    .speaker_lineup._mobile .list > .item {margin-bottom: 50px;}
    .data_hack header .mwc-navbar .navbar-brand span {font-size: 18px !important;}
    .ban_img img, .data_banner, .data_banner .item {min-height: 480px;}
    .data_hack footer .ft {justify-content: center;}
    .sponsors .fyt p {font-size: 24px !important;line-height: 30px !important;}
    .faq .bdy .accordion-item > h2.accordion-header button {font-size: 20px !important;line-height: 26px !important;}
    .bdy._mob .btns {margin: 25px auto 0;}
    .agenda, .qus, .faq, .sponsors {padding: 50px 0;}
    .agenda .tpt .lft {margin-bottom: 30px;}
    .qus .tab-content .box .img h3 {font-size: 16px !important;line-height: 16px !important;}
    .qus .tab-content .box > .img > h3:first-child {top: 133px;left: -65px;}
    .qus .tab-content .box .img h3::before {width: 50px;height: 50px;}
    .qus .tab-content .box .img h3:first-child::after {top: 10px;right: -85px;width: 155px;}
    .qus .tab-content .box .img h3:nth-child(2) {right: -40px;top: 85px;}
    .qus .tab-content .box .img h3:nth-child(2)::after {top: 5px;left: -85px;width: 195px;}
    .qus .tab-content .box .img h3:nth-child(3)::before {left: -125px;}
    .qus .tab-content .box .img h3:nth-child(3)::after {left: -110px;width: 180px;top: 10px;}
    .cnt {padding: 55px 0;}
    .faq .bdy .accordion-item > h2.accordion-header, .faq .bdy .accordion-item > h2.accordion-header button {width: 100%;overflow: hidden;}
    .faq .bdy .accordion-item:last-child > h2.accordion-header::after {width: 72px;height: 72px;border-width: 36px;}
    .tiwrap {margin-top: 30px;}
    .mwc-banner, .mwc-banner .carousel-inner .carousel-item > img {min-height: 532px; margin-top: 25px;}
    .mwc-banner .mwc-con .yeartxt svg {width: 160px; height: 107px;}
    .qus .nav-pills a {padding: 15px;}
    div#new_speaker .psitemimg {height: 325px;}
    div#new_speaker .psitemimg img {width: auto !important;height: 325px;}
    div#new_speaker a.psitem h4 {font-size: 19px;line-height: 30px;}
    div#about ul li {width: 100%;margin-bottom: 15px;}
    div#about h2 {margin-bottom: 35px !important;}
    ._frm {flex-direction: column;align-items: flex-start;}
    ._frm > div:not(:last-child) {margin-bottom: 10px;}
    #mc_embed_signup .mc-field-group {width: 100% !important;}
    .frm_blk {flex-direction: column;}
    .frm_blk > ul {width: 100%;}
    .fade.asc-popup.modal .modal-dialog .modal-content .modal-body {padding: 30px 15px 15px;}
    .fade.asc-popup.modal .modal-dialog .modal-content #mc_embed_signup .clsbtn.btn.btn-primary {right: -16px;}
    .pop_scroll {max-height: 550px;margin-bottom: 15px;}
    .data_banner {margin-top: 100px;}
    .data_hack .navbar-collapse {top: 60px;}
    .htd h2 {font-size: 36px !important;}
    .in_prices h2{font-size: 50px !important;line-height: 50px !important;margin-bottom: 0px !important;}
    .img_sec {margin: 35px 0 0;flex-direction: column;}
    .img_sec ._ig {width: 80%;}
    .in_prices, .wht_build, .faq_sec, .linked_btns {padding: 30px 0;}
    .wht_build h3 {font-size: 40px !important;line-height: 40px !important;margin-bottom: 25px !important;}
    .wht_build h4 {font-size: 28px !important;line-height: 32px !important;}
    .wht_build h5, .sub_sec .head p {font-size: 18px !important;line-height: 32px !important;}
    .wht_build p {font-size: 18px !important;padding: 0;}
    .sub_sec .head h2, .sub_sec .head h2 span {font-size: 32px !important;line-height: 36px !important;}
    .sub_sec .head {margin-bottom: 30px;}
    .sub_sec .bdys .boxs > h3 {font-size: 26px !important;}
    .sub_sec .bdys .boxs .box .bks .icon {width: 45px;}
    .price_cate {padding: 0;}
    .reg_sec h2 {font-size: 45px !important;line-height: 45px !important;}
    .reg_sec p {font-size: 20px !important;margin-bottom: 30px !important;}
    .spon_sec h2 {font-size: 45px !important;line-height: 45px !important;}
    .spon_sec h5 {font-size: 18px !important;}
    .data_banner .ban_cnt h2 {line-height: 40px !important;max-width: 100%;margin-bottom: 15px !important;font-size: 30px !important;}
    .data_banner {        margin-top: 100px;     max-height: 320px;     min-height: 320px; }
    .sft_box h2 {font-size: 55px !important;line-height: 55px !important;}
    .sft_box .d-flex .box:first-child {border-right: none;}
    .sub_sec2 p {text-align: left;}
    .sub_sec2 h2 {font-size: 34px !important;line-height: 34px !important;}
    .sub_sec2 h2 span {font-size: 26px !important;}
    .in_prices h3 {font-size: 50px !important;line-height: 50px !important;}
    .in_prices h2 {font-size: 36px !important;}
    .in_prices a {font-size: 20px !important;}
    .resource p, .resource ul li {font-size: 18px !important;}
    .resource .txt, .scdle .txt {padding-left: 0;}
    .scdle h5 {font-size: 24px !important;}
    .scdle p {font-size: 18px !important;padding-left: 30px !important;}
    .sft_box .d-flex .box {padding: 25px;}
    .sft_box h2 {font-size: 35px !important;line-height: 35px !important;}
    .banimgwrap img {
        height: 60px;
    }
}
 
@media (max-width: 480px) {
    div#Speakers > h2 {font-size: 26px!important;line-height: 32px;padding: 0;}
    .c_banner .c_banner_txt p {letter-spacing: 3px;}
    .mwc-about img {margin-bottom: 30px;}
    .mwc-about .row .col-md-7 {width: 95%;}
    .mwc-about-conr p {font-size: 15px;}
    .pillar_boday .blk {width: 70%;}
    .spk_blk {width: 100%;}
    div#awards h2 {margin-bottom: 25px!important;font-size: 26px!important;}
    div#awards .boxed p {margin: 0;font-size: 16px !important;line-height: 26px;}
    div#awards .boxed {padding: 20px 25px;}
    div#contact form .frm {width: 100%; margin-bottom: 15px;}
    div#contact form .frm input {padding: 7px 10px;}
    div#contact form .frm label {margin-bottom: 8px;width: 100%;font-size: 18px!important;line-height: 30px!important;}
    .d-icon {width: 30px;height: 30px;}
    .d-icon img {height: 30px!important;}
    div#About .txt .mwc-about-conr h2 {font-size: 26px !important;line-height: 35px;}
    div#About .txt .mwc-about-conr > p, div#About .txt .mwc-about-conr .trt ul li p {font-size: 16px !important;line-height: 24px;text-align: left;}
    div#About .txt .mwc-about-conr .trt h3 {font-size: 28px !important;line-height: 35px;margin: 0!important;}
    div#archived h2, div#contact h2 {font-size: 28px !important;line-height: 35px;margin-bottom: 30px!important;}
    .btn-custom {padding: 0 25px !important;}
    div#contact form .frm:last-child button {font-size: 16px;}
    .owl-theme3 .item {width: 100%;}
    div#fodh {padding: 65px 0;}
    div#fodh h2 {font-size: 45px !important;line-height: 45px !important;margin-bottom: 25px !important;}
    div#fodh p {font-size: 18px !important;line-height: 26px !important;margin-bottom: 30px;}
    .data_hack header .mwc-navbar .navbar-brand span {font-size: 13px !important;}
    .data_hack header .mwc-navbar, .data_hack footer {height: 75px;}
    .data_banner {margin-top: 75px;}
    .agenda, .qus, .faq, .sponsors {padding: 50px 0;}
    .agenda h2, .winner h2, .faq h2, .sponsors .top h2 {font-size: 45px !important;line-height: 45px !important;}
    .winner .btns .btn, .sponsors .bdy .btns .btn {height: 55px;font-size: 18px !important;}
    .sponsors .fyt {padding-top: 30px;}
    .sponsors .top {margin-bottom: 50px;}
    .data_hack footer .ft h4 {padding-right: 175px;}
    .data_hack footer .ft h4::after {width: 160px;}
    .faq .bdy .accordion-item > h2.accordion-header button {font-size: 15px !important;line-height: 24px !important;}
    .faq .bdy .rft .btns .btn, .bdy._mob .btns .btn {font-size: 18px !important;height: 55px;}
    .cnt {padding: 45px 0;}
    .data_banner .ban_cnt h2, .data_banner .ban_cnt h2 a {font-size: 35px !important;margin-bottom: 50px !important;line-height: 35px !important;-webkit-text-stroke: 1.5px white;}
    .data_banner .ban_cnt p {font-size: 20px !important;line-height: 28px !important;}
    .ban_img img, .data_banner, .data_banner .item {min-height: 425px;}
    .qus .tab-content .box > div h3 {font-size: 20px !important;}
    .qus .tab-content .box > .img > h3:first-child {top: 37%;left: -60px;}
    .qus .tab-content .box .img h3:first-child::after {top: 10px;right: -40px;width: 115px;}
    .qus .tab-content .box > .img > h3:first-child::before {right: -55px;}
    .qus .tab-content .box .img h3:nth-child(2)::before {left: -85px;}
    .qus .tab-content .box .img h3:nth-child(2)::after {top: 0px;left: -70px;width: 180px;}
    .qus .tab-content .box .img h3:nth-child(3)::before {left: -95px;}
    .qus .tab-content .box .img h3:nth-child(3)::after {left: -77px;width: 150px;top: 5px;}
    .qus .nav-pills a {font-size: 28px !important;line-height: 30px !important;padding: 10px 10px 3px 10px;text-shadow: none;}
    .qus .tab-content p {font-size: 18px !important;}
    .qus .nav-pills {margin-bottom: 40px;}
    .ft_btns .btns .btn {width: 100%;max-width: 250px;}
    .qus .tab-content .tab-pane {margin-top: 35px;}
    .asc-popup2 .modal-content h4 {font-size: 20px !important;}
    .asc-popup2 .modal-content h4 + .btn {font-size: 11px !important;line-height: 20px !important;height: 50px !important;text-transform: lowercase;word-break: break-all;padding: 10px;}
    .winner p {font-size: 20px !important;line-height: 28px !important;}
    video#video {height: 700px;}
    .faq .bdy .accordion-item {overflow: hidden;}
    .faq .bdy .accordion-item > h2.accordion-header::after {border-width: 34px;}
    .faq .bdy .rft .tab-content > .tab-pane p, .faq .bdy .rft .tab-content > .tab-pane ul li, .faq .bdy .accordion-item .accordion-body p, .faq .bdy .accordion-item .accordion-body ul li {font-size: 16px !important;line-height: 24px !important;}
    .tiwrap img:first-child {margin-right: 15px;}
    .tiwrap img {margin-left: 15px;}
    .tiwrap h4 {font-size: 22px !important;}
    .qus {padding-top: 25px;}
    .commmunitywrap h2 {font-size: 30px !important;line-height: 30px;}
    .builtwallet li {margin: 0px 5px 10px;}
    .new-accor .accordion-header .accordion-button {font-size: 17px;height: 50px;line-height: 36px;padding-right: 35px;}
    .sponsors .bdy .btns {margin-top: 10px;}
    .modal.display-block {min-height: 550px;}
    .builtwallet .developlink:first-child > a img {max-width: 115px;}
    .sub_logo span {font-size: 8px;}
    .mwc-navbar .sub_logo img {max-width: 80px !important;}
    .sub_logo {left: 0;}
    .v-head h4 {line-height: 40px;margin-bottom: 20px !important;}
    .new-accor .accordion-item {padding-right: 10px;}
    .data_hack footer {height: auto;}
    .data_hack footer ul li {font-size: 17px !important;line-height: 36px;}
    .data_banner .ban_cnt h2, .data_banner .ban_cnt h2 a {font-size: 20px !important;margin-bottom: 20px !important;line-height: 26px !important;-webkit-text-stroke: unset;}
    .data_banner .ban_cnt p {font-size: 16px !important;}
    .dt_future {padding: 30px 0;}
    .htd h2 {font-size: 26px !important;line-height: 34px !important;margin-bottom: 30px !important;}
    .bdy .img {width: 85%;margin: 0 auto 25px;}
    .dt_future .btns {margin-bottom: 25px !important;flex-direction: column;}
    .dt_future .btns .btn {font-size: 18px !important;width: 85%;padding: 10px 15px;}
    .dt_future .btns .btn:not(:last-child) {margin-bottom: 15px;}
    .bdy .txt ul li {font-size: 18px !important;}
    .bdy .txt ul + p {font-size: 16px !important;}
    .abt_sec, .sub_sec {padding: 30px 0;}
    .abt_sec h2, .tracks_chag h2, .resource h2, .scdle h2, .price_cate h2 {font-size: 35px !important;line-height: 35px !important;}
    .abt_sec .blk > div h4 {font-size: 24px !important;line-height: 30px !important;}
    .abt_sec .blk > div p, .agenda_sec .txt .blks p {font-size: 16px !important;line-height: 28px !important;}
    .tracks_chag p {font-size: 18px !important;}
    .tracks_chag .bdy .img {width: 100%;position: relative;}
    .tracks_chag .bdy .txt {height: auto;}
    .tracks_chag .bdy .txt .blks {max-width: 100%;position: relative !important;top: unset !important;left: unset !important;right: unset !important;bottom: unset !important;}
    .tracks_chag .bdy .txt .blks:not(:last-child) {margin-bottom: 25px;}
    .tracks_chag .bdy .txt .blks::before, .tracks_chag .bdy .txt .blks::after {display: none !important;}
    .tracks_chag .bdy {margin-top: 20px;}
    .sft_box .d-flex .box {padding: 25px 25px 15px;}
    .agenda_sec .img, .jud_sec .d-flex .img, .reg_sec .blks > .img {width: 85%;margin: 0 auto 30px;}
    .agenda_sec .txt h3 {font-size: 35px !important;line-height: 35px !important;margin-bottom: 30px !important;}
    .agenda_sec .txt .blks:not(:last-child) {margin-bottom: 25px;}
    .agenda_sec .txt .blks h5 {font-size: 20px !important;line-height: 32px !important;}
    .resource h4 {font-size: 26px !important;margin-bottom: 15px !important;}
    .resource p {font-size: 16px !important;margin-bottom: 20px;}
    .resource h5 {font-size: 22px !important;line-height: 30px !important;}
    .resource ul li {font-size: 16px !important;}
    .in_prices h2 {font-size: 40px !important;}
    .wht_build h3 {font-size: 35px !important;line-height: 35px !important;margin-bottom: 20px !important;}
    .wht_build h4 {font-size: 24px !important;line-height: 30px !important;}
    .wht_build h5, .sub_sec .head p, .wht_build p {font-size: 16px !important;line-height: 28px !important;}
    .wht_build .bks .txt h3 {font-size: 22px !important;}
    .wht_build .bks .txt ul li {font-size: 16px !important;}
    .wht_build .bks > div {width: 85%;margin: 0 auto 25px;}
    .sub_sec .bdys .boxs > h3 {font-size: 20px !important;}
    .sub_sec .bdys .boxs .box {padding: 20px;}
    .sub_sec .bdys .boxs .box .bks .icon {width: 35px;}
    .sub_sec .bdys .boxs .box .bks .txt {width: calc(100% - 35px);}
    .sub_sec .bdys .boxs .box .bks .txt h4 {font-size: 20px !important;line-height: 26px !important;margin-bottom: 10px;}
    .price_cate a {font-size: 20px !important;line-height: 26px !important;padding: 15px 50px;}
    .jud_sec .d-flex .txt h3 {font-size: 28px !important;}
    .jud_sec .d-flex .txt h4 {font-size: 22px !important;}
    .jud_sec .d-flex .txt p {font-size: 16px !important;}
    .reg_sec h2 {font-size: 35px !important;line-height: 35px !important;margin-bottom: 25px !important;}
    .reg_sec p {font-size: 18px !important;margin-bottom: 26px !important;}
    .reg_sec button {font-size: 18px !important;padding: 10px 25px;}
    .reg_sec .blks > .txt {padding: 20px;}
    .reg_sec .blks > .txt p, .spon_sec p {font-size: 16px !important;line-height: 28px;}
    .text_sec, .data_agenda, .reg_sec, .sft_box, .resource, .jud_sec, .price_cate, .spon_sec {width: 100%;padding: 30px 0;}
    .faq_sec > div > h2, .data_hack footer h2 {font-size: 28px !important;line-height: 30px !important;margin-bottom: 15px !important;}
    .faq_sec .faq-accor .accordion-item h2 button {font-size: 18px !important;height: 50px;}
    .accordion-body p, .accordion-body {font-size: 16px;}
    .spon_sec h2 {font-size: 35px !important;line-height: 35px !important;margin-bottom: 20px !important;}
    .spon_sec .spn_blk {margin-bottom: 20px;flex-direction: column;}
    .spon_sec .spn_blk .logo, .spon_sec .spn_blk:nth-child(2) .logo {width: 80%;}
    .linked_btns .d-flex .btn {padding: 10px;font-size: 18px !important;}
    .linked_btns {padding-bottom: 10px;}
    .data_hack footer {padding: 30px 0;}
    .data_hack footer .social_icons ul li a {font-size: 35px;}
    .social_icons {margin-top: 20px;}
    .dt_future .bdy .txt p {font-size: 18px !important;line-height: 28px !important;}
    .dt_future .bdy .txt h4 {font-size: 26px !important;line-height: 30px !important;margin-bottom: 20px;}
    .dt_future .bdy .txt ul li {font-size: 20px !important;}
    .bdy .txt p, .tracks_chag .bdy .txt .blks ul li, .tracks_chag .bdy .txt p {font-size: 16px !important;}
    .text_sec p {font-size: 18px !important;line-height: 28px !important;}
    .data_banner {max-height: 300px;min-height: 300px;}
    .data_hack .sub_logo span {font-size: 10px;}
    .logo_s {padding: 0;}
    .data_hack .mwc-navbar .navbar-brand img {height: 60px;}
    .video_sec .cont_sec {width: 100%;}
    .video_sec .cont_sec > .blk span {font-size: 24px;line-height: 36px;}
    .video_sec .cont_sec > .blk p {font-size: 20px;line-height: 30px;}
    .video_sec .cont_sec > .blk .icon img {width: 50px;}
    .sub_sec2 h2 {font-size: 30px !important;line-height: 45px !important;}
    .sub_sec2 h2 span {font-size: 20px !important;}
    .sub_sec2 p {font-size: 16px !important;}
    .tracks_chag .head h2 {font-size: 40px !important;line-height: 40px !important;}
    .in_prices h3 {font-size: 30px !important;line-height: 30px !important;}
    .in_prices a {font-size: 16px !important;padding: 5px 10px;}
    .data_hack .sub_logo {position: relative;flex-direction: column;justify-content: flex-start;align-items: flex-start;}
    .mwc-navbar .navbar-brand {margin-right: 5px;}
    .faq_sec .faq-accor .accordion-item h2 button {font-size: 16px !important;height: 60px;line-height: 24px;}
    .data_hack header .mwc-navbar li .dropdown-menu li .blks h5 {font-size: 16px;}
    .data_hack header .mwc-navbar li .dropdown-menu li .blks p {font-size: 14px;line-height: 20px;}
    .data_hack header .mwc-navbar li .dropdown-menu li:not(:last-child) {margin-bottom: 10px !important;}
    .data_hack .navbar-expand-lg .navbar-nav .dropdown-menu {padding: 15px;}
    .data_hack .navbar-collapse {top: 75px;}
    .data_hack header .mwc-navbar.mwc-fillbg1 .navbar-collapse {padding-top: 0;overflow: hidden;overflow-y: auto;max-height: 500px;box-shadow: none;}
    .abt_cnt .blks h4 {font-size: 22px;line-height: 22px;}
    .abt_cnt .blks p {font-size: 16px;line-height: 26px;}
    .agenda {padding: 100px 0 35px;}
    .agenda h2 {font-size: 30px !important;line-height: 30px !important;margin-bottom: 25px !important;}
    .agenda ul {margin-top: 0;}
    .agenda ul li:not(:last-child) {margin-bottom: 20px;}
    .agenda ul li h5 {font-size: 18px !important;}
    .data_banner video { width: 535px;  margin-left: 50%;    transform: translateX(-50%);}
    .banimgwrap img { height: 50px;}
    .mwc-banner .carousel-inner .carousel-item > img {min-height: 460px;}
}

@media (max-height: 1080px) {
    .pop_scroll {max-height: 700px;}
}
@media (max-height: 1000px) {
    .pop_scroll {max-height: 660px;}
}
@media (max-height: 900px) {
    .pop_scroll {max-height: 550px;margin-bottom: 20px;}
}
@media (max-height: 800px) {
    .pop_scroll {max-height: 475px;}
}
@media (max-height: 700px) {
    .pop_scroll {max-height: 415px;}
}
/*RESPONSIVE*/


/*VERSION2 THEME 2024 */