@font-face {
    font-family: 'PP Telegraf';
    src: url('PPTelegraf-Regular.eot');
    src: url('PPTelegraf-Regular.eot?#iefix') format('embedded-opentype'),
        url('PPTelegraf-Regular.woff2') format('woff2'),
        url('PPTelegraf-Regular.woff') format('woff'),
        url('PPTelegraf-Regular.ttf') format('truetype'),
        url('PPTelegraf-Regular.svg#PPTelegraf-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PP Telegraf';
    src: url('PPTelegraf-UltraBold.eot');
    src: url('PPTelegraf-UltraBold.eot?#iefix') format('embedded-opentype'),
        url('PPTelegraf-UltraBold.woff2') format('woff2'),
        url('PPTelegraf-UltraBold.woff') format('woff'),
        url('PPTelegraf-UltraBold.ttf') format('truetype'),
        url('PPTelegraf-UltraBold.svg#PPTelegraf-UltraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'PP Telegraf';
    src: url('PPTelegraf-UltraLight.eot');
    src: url('PPTelegraf-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('PPTelegraf-UltraLight.woff2') format('woff2'),
        url('PPTelegraf-UltraLight.woff') format('woff'),
        url('PPTelegraf-UltraLight.ttf') format('truetype'),
        url('PPTelegraf-UltraLight.svg#PPTelegraf-UltraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Code Pro';
    src: url('Code-Pro.eot');
    src: url('Code-Pro.eot?#iefix') format('embedded-opentype'),
        url('Code-Pro.woff2') format('woff2'),
        url('Code-Pro.woff') format('woff'),
        url('Code-Pro.ttf') format('truetype'),
        url('Code-Pro.svg#Code-Pro') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Code Pro';
    src: url('Code-Pro-Bold.eot');
    src: url('Code-Pro-Bold.eot?#iefix') format('embedded-opentype'),
        url('Code-Pro-Bold.woff2') format('woff2'),
        url('Code-Pro-Bold.woff') format('woff'),
        url('Code-Pro-Bold.ttf') format('truetype'),
        url('Code-Pro-Bold.svg#Code-Pro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Code Pro';
    src: url('Code-Pro-Light.eot');
    src: url('Code-Pro-Light.eot?#iefix') format('embedded-opentype'),
        url('Code-Pro-Light.woff2') format('woff2'),
        url('Code-Pro-Light.woff') format('woff'),
        url('Code-Pro-Light.ttf') format('truetype'),
        url('Code-Pro-Light.svg#Code-Pro-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Bold.eot');
    src: url('OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-Bold.woff2') format('woff2'),
        url('OpenSans-Bold.woff') format('woff'),
        url('OpenSans-Bold.ttf') format('truetype'),
        url('OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Medium.eot');
    src: url('OpenSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-Medium.woff2') format('woff2'),
        url('OpenSans-Medium.woff') format('woff'),
        url('OpenSans-Medium.ttf') format('truetype'),
        url('OpenSans-Medium.svg#OpenSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Regular.eot');
    src: url('OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-Regular.woff2') format('woff2'),
        url('OpenSans-Regular.woff') format('woff'),
        url('OpenSans-Regular.ttf') format('truetype'),
        url('OpenSans-Regular.svg#OpenSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-SemiBold.eot');
    src: url('OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-SemiBold.woff2') format('woff2'),
        url('OpenSans-SemiBold.woff') format('woff'),
        url('OpenSans-SemiBold.ttf') format('truetype'),
        url('OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Light.eot');
    src: url('OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('OpenSans-Light.woff2') format('woff2'),
        url('OpenSans-Light.woff') format('woff'),
        url('OpenSans-Light.ttf') format('truetype'),
        url('OpenSans-Light.svg#OpenSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans Condensed';
    src: url('OpenSansCondensed-MediumItalic.eot');
    src: url('OpenSansCondensed-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('OpenSansCondensed-MediumItalic.woff2') format('woff2'),
        url('OpenSansCondensed-MediumItalic.woff') format('woff'),
        url('OpenSansCondensed-MediumItalic.ttf') format('truetype'),
        url('OpenSansCondensed-MediumItalic.svg#OpenSansCondensed-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('DMSans-Bold.eot');
    src: url('DMSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('DMSans-Bold.woff2') format('woff2'),
        url('DMSans-Bold.woff') format('woff'),
        url('DMSans-Bold.ttf') format('truetype'),
        url('DMSans-Bold.svg#DMSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('DMSans-Medium.eot');
    src: url('DMSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('DMSans-Medium.woff2') format('woff2'),
        url('DMSans-Medium.woff') format('woff'),
        url('DMSans-Medium.ttf') format('truetype'),
        url('DMSans-Medium.svg#DMSans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('DMSans-Light.eot');
    src: url('DMSans-Light.eot?#iefix') format('embedded-opentype'),
        url('DMSans-Light.woff2') format('woff2'),
        url('DMSans-Light.woff') format('woff'),
        url('DMSans-Light.ttf') format('truetype'),
        url('DMSans-Light.svg#DMSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('DMSans-SemiBold.eot');
    src: url('DMSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('DMSans-SemiBold.woff2') format('woff2'),
        url('DMSans-SemiBold.woff') format('woff'),
        url('DMSans-SemiBold.ttf') format('truetype'),
        url('DMSans-SemiBold.svg#DMSans-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Sans';
    src: url('DMSans-Regular.eot');
    src: url('DMSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('DMSans-Regular.woff2') format('woff2'),
        url('DMSans-Regular.woff') format('woff'),
        url('DMSans-Regular.ttf') format('truetype'),
        url('DMSans-Regular.svg#DMSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Gabriel Sans';
    src: url('Gabriel-Sans-Medium.eot');
    src: url('Gabriel-Sans-Medium.eot?#iefix') format('embedded-opentype'),
        url('Gabriel-Sans-Medium.woff2') format('woff2'),
        url('Gabriel-Sans-Medium.woff') format('woff'),
        url('Gabriel-Sans-Medium.ttf') format('truetype'),
        url('Gabriel-Sans-Medium.svg#Gabriel-Sans-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gabriel Sans';
    src: url('Gabriel-Sans-Black.eot');
    src: url('Gabriel-Sans-Black.eot?#iefix') format('embedded-opentype'),
        url('Gabriel-Sans-Black.woff2') format('woff2'),
        url('Gabriel-Sans-Black.woff') format('woff'),
        url('Gabriel-Sans-Black.ttf') format('truetype'),
        url('Gabriel-Sans-Black.svg#Gabriel-Sans-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gabriel Sans Normal';
    src: url('Gabriel-Sans-Normal.eot');
    src: url('Gabriel-Sans-Normal.eot?#iefix') format('embedded-opentype'),
        url('Gabriel-Sans-Normal.woff2') format('woff2'),
        url('Gabriel-Sans-Normal.woff') format('woff'),
        url('Gabriel-Sans-Normal.ttf') format('truetype'),
        url('Gabriel-Sans-Normal.svg#Gabriel-Sans-Normal') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gabriel Sans';
    src: url('Gabriel-Sans-Thin.eot');
    src: url('Gabriel-Sans-Thin.eot?#iefix') format('embedded-opentype'),
        url('Gabriel-Sans-Thin.woff2') format('woff2'),
        url('Gabriel-Sans-Thin.woff') format('woff'),
        url('Gabriel-Sans-Thin.ttf') format('truetype'),
        url('Gabriel-Sans-Thin.svg#Gabriel-Sans-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gabriel Sans';
    src: url('Gabriel-Sans-Bold.eot');
    src: url('Gabriel-Sans-Bold.eot?#iefix') format('embedded-opentype'),
        url('Gabriel-Sans-Bold.woff2') format('woff2'),
        url('Gabriel-Sans-Bold.woff') format('woff'),
        url('Gabriel-Sans-Bold.ttf') format('truetype'),
        url('Gabriel-Sans-Bold.svg#Gabriel-Sans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueMachina-Light';
    src: url('NeueMachina-Light.eot');
    src: url('NeueMachina-Light.eot?#iefix') format('embedded-opentype'),
        url('NeueMachina-Light.woff2') format('woff2'),
        url('NeueMachina-Light.woff') format('woff'),
        url('NeueMachina-Light.ttf') format('truetype'),
        url('NeueMachina-Light.svg#NeueMachina-Light') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueMachina-Regular';
    src: url('NeueMachina-Regular.eot');
    src: url('NeueMachina-Regular.eot?#iefix') format('embedded-opentype'),
        url('NeueMachina-Regular.woff2') format('woff2'),
        url('NeueMachina-Regular.woff') format('woff'),
        url('NeueMachina-Regular.ttf') format('truetype'),
        url('NeueMachina-Regular.svg#NeueMachina-Regular') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NeueMachina-Ultrabold';
    src: url('NeueMachina-Ultrabold.eot');
    src: url('NeueMachina-Ultrabold.eot?#iefix') format('embedded-opentype'),
        url('NeueMachina-Ultrabold.woff2') format('woff2'),
        url('NeueMachina-Ultrabold.woff') format('woff'),
        url('NeueMachina-Ultrabold.ttf') format('truetype'),
        url('NeueMachina-Ultrabold.svg#NeueMachina-Ultrabold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Halcom-Medium';
    src: url('Halcom-Medium.eot');
    src: url('Halcom-Medium.eot?#iefix') format('embedded-opentype'),
        url('Halcom-Medium.woff2') format('woff2'),
        url('Halcom-Medium.woff') format('woff'),
        url('Halcom-Medium.ttf') format('truetype'),
        url('Halcom-Medium.svg#Halcom-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Halcom-Regular';
    src: url('Halcom-Regular.eot');
    src: url('Halcom-Regular.eot?#iefix') format('embedded-opentype'),
        url('Halcom-Regular.woff2') format('woff2'),
        url('Halcom-Regular.woff') format('woff'),
        url('Halcom-Regular.ttf') format('truetype'),
        url('Halcom-Regular.svg#Halcom-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Halcom-Bold';
    src: url('Halcom-Bold.eot');
    src: url('Halcom-Bold.eot?#iefix') format('embedded-opentype'),
        url('Halcom-Bold.woff2') format('woff2'),
        url('Halcom-Bold.woff') format('woff'),
        url('Halcom-Bold.ttf') format('truetype'),
        url('Halcom-Bold.svg#Halcom-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cy-Regular';
    src: url('Cy-Regular.eot');
    src: url('Cy-Regular.eot?#iefix') format('embedded-opentype'),
        url('Cy-Regular.woff2') format('woff2'),
        url('Cy-Regular.woff') format('woff'),
        url('Cy-Regular.ttf') format('truetype'),
        url('Cy-Regular.svg#Cy-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cy-SemiBold';
    src: url('Cy-SemiBold.eot');
    src: url('Cy-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Cy-SemiBold.woff2') format('woff2'),
        url('Cy-SemiBold.woff') format('woff'),
        url('Cy-SemiBold.ttf') format('truetype'),
        url('Cy-SemiBold.svg#Cy-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cy-Bold';
    src: url('Cy-Bold.eot');
    src: url('Cy-Bold.eot?#iefix') format('embedded-opentype'),
        url('Cy-Bold.woff2') format('woff2'),
        url('Cy-Bold.woff') format('woff'),
        url('Cy-Bold.ttf') format('truetype'),
        url('Cy-Bold.svg#Cy-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cy-ExtraBold';
    src: url('Cy-ExtraBold.eot');
    src: url('Cy-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Cy-ExtraBold.woff2') format('woff2'),
        url('Cy-ExtraBold.woff') format('woff'),
        url('Cy-ExtraBold.ttf') format('truetype'),
        url('Cy-ExtraBold.svg#Cy-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Medium';
    src: url('Inter18pt-Medium.eot');
    src: url('Inter18pt-Medium.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-Medium.woff2') format('woff2'),
        url('Inter18pt-Medium.woff') format('woff'),
        url('Inter18pt-Medium.ttf') format('truetype'),
        url('Inter18pt-Medium.svg#Inter18pt-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Regular';
    src: url('Inter18pt-Regular.eot');
    src: url('Inter18pt-Regular.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-Regular.woff2') format('woff2'),
        url('Inter18pt-Regular.woff') format('woff'),
        url('Inter18pt-Regular.ttf') format('truetype'),
        url('Inter18pt-Regular.svg#Inter18pt-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Bold';
    src: url('Inter18pt-Bold.eot');
    src: url('Inter18pt-Bold.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-Bold.woff2') format('woff2'),
        url('Inter18pt-Bold.woff') format('woff'),
        url('Inter18pt-Bold.ttf') format('truetype'),
        url('Inter18pt-Bold.svg#Inter18pt-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-Light';
    src: url('Inter18pt-Light.eot');
    src: url('Inter18pt-Light.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-Light.woff2') format('woff2'),
        url('Inter18pt-Light.woff') format('woff'),
        url('Inter18pt-Light.ttf') format('truetype'),
        url('Inter18pt-Light.svg#Inter18pt-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-ExtraBold';
    src: url('Inter18pt-ExtraBold.eot');
    src: url('Inter18pt-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-ExtraBold.woff2') format('woff2'),
        url('Inter18pt-ExtraBold.woff') format('woff'),
        url('Inter18pt-ExtraBold.ttf') format('truetype'),
        url('Inter18pt-ExtraBold.svg#Inter18pt-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter-SemiBold';
    src: url('Inter18pt-SemiBold.eot');
    src: url('Inter18pt-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Inter18pt-SemiBold.woff2') format('woff2'),
        url('Inter18pt-SemiBold.woff') format('woff'),
        url('Inter18pt-SemiBold.ttf') format('truetype'),
        url('Inter18pt-SemiBold.svg#Inter18pt-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BodoniCondC';
    src: url('BodoniCondC.eot');
    src: url('BodoniCondC.eot?#iefix') format('embedded-opentype'),
        url('BodoniCondC.woff2') format('woff2'),
        url('BodoniCondC.woff') format('woff'),
        url('BodoniCondC.ttf') format('truetype'),
        url('BodoniCondC.svg#BodoniCondC') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Flatory Sans';
    src: url('FlatorySans-400.eot');
    src: url('FlatorySans-400.eot?#iefix') format('embedded-opentype'),
        url('FlatorySans-400.woff2') format('woff2'),
        url('FlatorySans-400.woff') format('woff'),
        url('FlatorySans-400.ttf') format('truetype'),
        url('FlatorySans-400.svg#FlatorySans-400') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Flatory-Light';
    src: url('FlatorySans-300.eot');
    src: url('FlatorySans-300.eot?#iefix') format('embedded-opentype'),
        url('FlatorySans-300.woff2') format('woff2'),
        url('FlatorySans-300.woff') format('woff'),
        url('FlatorySans-300.ttf') format('truetype'),
        url('FlatorySans-300.svg#FlatorySans-300') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

