
#payment-form {
    width: 100%;
    min-width: 500px;
    align-self: center;    
    padding:15px 25px;
  }
  
  .form-div {
    margin: 0 auto;
    width: 72%;
  }
  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }
  
  #payment-element {
    margin-bottom: 24px;
  }
  #payment-element .p-FieldLabel Label{
      color:red
  }
  button#submit {
    margin: 30px 0 0 auto !important;
    width: 150px;
    background-color: rgb(254, 190, 152) !important;
    color: rgb(150, 57, 2) !important;
    border: 3px solid rgb(254,190,152) !important;
    box-shadow: none !important;
    border-radius: 0 !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    height: 48px;
}
button#submit:hover {
  color: rgb(254,190,152) !important;
  background: transparent !important;
}
.StripeElement + div {
  text-align: right;
}



  
  
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    text-align: center;
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  div#payment-element + div > label {
    display: block;
    color: #000;
    position: relative;
    padding-left: 20px;
    text-transform: none;
  }
  div#payment-element + div > label::before {
    position: absolute;
    left: 0;
    top: 0px;
  }
  div#payment-element + div > label a {
    color: #cc7700;
    
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .babpsuccess{ min-height: 400px; display: flex; width: 100%; flex-direction: column; justify-content: center; align-items: center;}
  
  @media(max-width:991px){
    #payment-form {width: 100%;min-width: 100%;align-self: center;padding: 40px 0px;}
    }
      